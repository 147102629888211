// Environmental.js
import { Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import FactorGraph from '../components/FactorGraph';
import '../styles/Environmental.css'; // Import the CSS file for custom styles

const Environmental = () => {
  // State to control the view mode (daily, monthly, yearly)
  const [viewMode, setViewMode] = useState('monthly');

  // Function to change the view mode
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <div className="environmental-container">
      {/* Centered button group with improved styling */}
      <div className="button-group-container">
        <ButtonGroup
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            boxShadow: 3,
          }}
        >
          <Button
            onClick={() => handleViewModeChange('daily')}
            variant={viewMode === 'daily' ? 'contained' : 'outlined'}
            sx={{ borderRadius: '20px 0 0 20px' }}
          >
            Tage
          </Button>
          <Button
            onClick={() => handleViewModeChange('monthly')}
            variant={viewMode === 'monthly' ? 'contained' : 'outlined'}
          >
            Monate
          </Button>
          <Button
            onClick={() => handleViewModeChange('yearly')}
            variant={viewMode === 'yearly' ? 'contained' : 'outlined'}
            sx={{ borderRadius: '0 20px 20px 0' }}
          >
            Jahre
          </Button>
        </ButtonGroup>
      </div>

      {/* Grid layout for the FactorGraphs */}
      <div className="factor-graphs-grid">
        <FactorGraph
          factor="benzin"
          factorLabel="Benzinverbrauch"
          unit="Liter"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="diesel"
          factorLabel="Dieselverbrauch"
          unit="Liter"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="erdgas"
          factorLabel="Erdgasverbrauch"
          unit="m³"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="wasserverbrauch"
          factorLabel="Wasserverbrauch"
          unit="m³"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="erzeugte_abfaelle"
          factorLabel="Erzeugte Abfälle"
          unit="Liter"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="kaeltemittel"
          factorLabel="Kältemittelverbrauch"
          unit="kg"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="nutzung_erneuerbarer_energien"
          factorLabel="Nutzung Erneuerbarer Energien"
          unit="kWh"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="stromverbrauch"
          factorLabel="Stromverbrauch"
          unit="kWh"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="technologienutzung"
          factorLabel="Technologienutzung"
          unit="Stunden"
          viewMode={viewMode}
        />
        <FactorGraph
          factor="transport_produkte"
          factorLabel="Transport der Produkte"
          unit="km"
          viewMode={viewMode}
        />
      </div>
    </div>
  );
};

export default Environmental;
