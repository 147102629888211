// auth0Utils.js
import axios from 'axios';

export const getToken = async () => {
  const response = await axios.post('https://dev-tftfltcthnzd0rlq.eu.auth0.com/oauth/token', {
    client_id: 'z6rSF5iuep8nE9hUBC362IgzjXZSjwwY',
    client_secret: 'KTiaX5pLtALQON20vReuZvJzlRUEP0JSQQfEGsW8uAsm9RqD01cIuk9Rj5e0Kg-S',
    audience: 'https://dev-tftfltcthnzd0rlq.eu.auth0.com/api/v2/',
    grant_type: 'client_credentials',
    scope: 'update:users',
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data.access_token;
};

export const changePassword = async (email) => {
  const token = await getToken();
  try {
    const response = await axios.post('https://dev-tftfltcthnzd0rlq.eu.auth0.com/api/v2/tickets/password-change', {
      client_id: 'z6rSF5iuep8nE9hUBC362IgzjXZSjwwY',
      email: email,
      connection_id: 'con_V2LEX8mi2D80icSw', // Reemplaza con el ID de tu conexión
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.ticket;
  } catch (error) {
    console.error('Error details:', error.response.data);
    throw error;
  }
};

export const changeEmail = async (userId, newEmail) => {
  const token = await getToken();
  try {
    const response = await axios.patch(`https://dev-tftfltcthnzd0rlq.eu.auth0.com/api/v2/users/${userId}`, {
      email: newEmail,
      email_verified: false // Establece a false para que el nuevo correo electrónico deba ser verificado
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error details:', error.response.data);
    throw error;
  }
};
