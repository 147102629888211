import { useAuth0 } from '@auth0/auth0-react';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Menü-Icon und Logout-Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Font Awesome
import React from 'react';
import '../styles/Header.css'; // Importiere Header-spezifische CSS-Stile

function Header({ toggleSidebar }) {
  const { user, logout } = useAuth0(); // Zugriff auf Benutzer und Logout-Funktion

  return (
    <nav className="custom-header">
      {/* Menü-Button für mobile Geräte */}
      <div className="d-flex align-items-center ms-3 d-block d-sm-none">
        <button className="btn btn-icon" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </div>

      {/* Begrüßung für den Benutzer auf größeren Bildschirmen */}
      <div className="d-flex align-items-center ms-3 d-none d-sm-block">
        <span className="navbar-text">Willkommen, {user?.name}!</span>
      </div>

      {/* Zentriertes Logo */}
      <div className="position-absolute top-50 start-50 translate-middle">
        <img
          src="https://lahnitsolutions.de/wp-content/uploads/2024/11/LIKS-Option-2-Icon-1.png"
          alt="LIKS Logo"
          className="logo"
        />
      </div>

      {/* Logout-Button mit Icon */}
      <div className="d-flex align-items-center me-3">
        <button className="btn btn-icon" onClick={() => logout({ returnTo: window.location.origin })}>
          <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        </button>
      </div>
    </nav>
  );
}

export default Header;