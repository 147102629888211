import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ChartComponent from '../components/ChartComponent';
import ControlsComponent from '../components/ControlsComponent';

const Heizoel = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewMode, setViewMode] = useState('daily');
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [chartType, setChartType] = useState('line');
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  // Estado para almacenar los resultados de análisis
  const [analysisResults, setAnalysisResults] = useState([]);

  // Función para obtener los datos desde la BD
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Obtener los datos al montar el componente
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Procesar los datos cada vez que cambien los filtros o los resultados
  useEffect(() => {
    const filteredAnalysisResults = analysisResults.filter(result => result.factor === 'heizoel');

    // Procesar los resultados para manejar entradas con períodos
    let processedResults = [];

    filteredAnalysisResults.forEach(result => {
      // Si el resultado tiene un 'date', usarlo directamente
      if (result.date) {
        const resultDate = new Date(result.date);
        processedResults.push({
          date: resultDate,
          value: Number(result.value),
          co2_aequivalent: Number(result.co2_aequivalent),
        });
      } else if (result.start_date && result.end_date) {
        const start = new Date(result.start_date);
        const end = new Date(result.end_date);
        const totalDays = (end - start) / (1000 * 60 * 60 * 24) + 1;
        if (totalDays <= 0) return;

        // Dependiendo del modo de vista, distribuir el valor
        if (viewMode === 'daily') {
          // Distribuir el valor sobre cada día
          let currentDate = new Date(start);
          const dailyValue = Number(result.value) / totalDays;
          const dailyCO2 = Number(result.co2_aequivalent) / totalDays;

          while (currentDate <= end) {
            processedResults.push({
              date: new Date(currentDate),
              value: dailyValue,
              co2_aequivalent: dailyCO2,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else if (viewMode === 'monthly') {
          // Distribuir el valor sobre meses
          let currentDate = new Date(start);
          const endMonth = end.getMonth() + end.getFullYear() * 12;
          const startMonth = start.getMonth() + start.getFullYear() * 12;
          const totalMonths = endMonth - startMonth + 1;
          const monthlyValue = Number(result.value) / totalMonths;
          const monthlyCO2 = Number(result.co2_aequivalent) / totalMonths;

          while (currentDate <= end) {
            const monthKey = currentDate.toLocaleString('de-DE', { month: 'short', year: 'numeric' });
            processedResults.push({
              date: monthKey,
              value: monthlyValue,
              co2_aequivalent: monthlyCO2,
            });
            // Avanzar al siguiente mes
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (viewMode === 'yearly') {
          // Distribuir el valor sobre años
          let currentYear = start.getFullYear();
          const endYear = end.getFullYear();
          const totalYears = endYear - currentYear + 1;
          const yearlyValue = Number(result.value) / totalYears;
          const yearlyCO2 = Number(result.co2_aequivalent) / totalYears;

          while (currentYear <= endYear) {
            processedResults.push({
              date: currentYear.toString(),
              value: yearlyValue,
              co2_aequivalent: yearlyCO2,
            });
            currentYear++;
          }
        }
      }
    });

    // Filtrar processedResults basado en startDate y endDate
    const filteredResults = processedResults.filter((result) => {
      if (viewMode === 'daily') {
        const resultDate = result.date;
        const isAfterStart = startDate ? resultDate >= startDate : true;
        const isBeforeEnd = endDate ? resultDate <= endDate : true;
        return isAfterStart && isBeforeEnd;
      } else {
        // Para mensual y anual, filtraremos en el paso de agregación
        return true;
      }
    });

    let dates = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      // Ordenar los resultados por fecha
      const sortedResults = filteredResults.sort((a, b) => a.date - b.date);

      dates = sortedResults.map(result => result.date.toISOString().split('T')[0]);
      values = sortedResults.map(result => result.value);
      co2Equivalent = sortedResults.map(result => result.co2_aequivalent);
    } else if (viewMode === 'monthly') {
      const monthlyDataValues = {};
      const monthlyDataCO2 = {};

      filteredResults.forEach(result => {
        const monthKey = result.date;
        if (!monthlyDataValues[monthKey]) {
          monthlyDataValues[monthKey] = 0;
          monthlyDataCO2[monthKey] = 0;
        }
        monthlyDataValues[monthKey] += result.value;
        monthlyDataCO2[monthKey] += result.co2_aequivalent;
      });

      // Filtrar meses basados en startDate y endDate
      const sortedMonths = Object.keys(monthlyDataValues).sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');
        const dateA = new Date(`${monthA} ${yearA}`);
        const dateB = new Date(`${monthB} ${yearB}`);
        return dateA - dateB;
      }).filter(monthKey => {
        const [monthStr, yearStr] = monthKey.split(' ');
        const date = new Date(`${monthStr} ${yearStr}`);
        const isAfterStart = startDate ? date >= startDate : true;
        const isBeforeEnd = endDate ? date <= endDate : true;
        return isAfterStart && isBeforeEnd;
      });

      dates = sortedMonths;
      values = sortedMonths.map(monthKey => monthlyDataValues[monthKey]);
      co2Equivalent = sortedMonths.map(monthKey => monthlyDataCO2[monthKey]);
    } else if (viewMode === 'yearly') {
      const yearlyDataValues = {};
      const yearlyDataCO2 = {};

      filteredResults.forEach(result => {
        const yearKey = result.date;
        if (!yearlyDataValues[yearKey]) {
          yearlyDataValues[yearKey] = 0;
          yearlyDataCO2[yearKey] = 0;
        }
        yearlyDataValues[yearKey] += result.value;
        yearlyDataCO2[yearKey] += result.co2_aequivalent;
      });

      // Filtrar años basados en startDate y endDate
      const sortedYears = Object.keys(yearlyDataValues).sort().filter(year => {
        const date = new Date(`${year}-01-01`);
        const isAfterStart = startDate ? date >= startDate : true;
        const isBeforeEnd = endDate ? date <= endDate : true;
        return isAfterStart && isBeforeEnd;
      });

      dates = sortedYears;
      values = sortedYears.map(yearKey => yearlyDataValues[yearKey]);
      co2Equivalent = sortedYears.map(yearKey => yearlyDataCO2[yearKey]);
    }

    setData({
      labels: dates,
      datasets: [
        {
          label: 'Heizölverbrauch (Liter)',
          data: values,
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.1)',
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(255,159,64,0.1)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    });

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'Liter',
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    });
  }, [analysisResults, startDate, endDate, viewMode]);

  // Función para descargar el gráfico en el formato seleccionado
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image(downloadFormat);
    const link = document.createElement('a');
    link.href = url;
    link.download = `heizoelverbrauch_co2.${downloadFormat}`;
    link.click();
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Gráfico con mayor altura y separación */}
      {data.labels && data.labels.length > 0 ? (
        <div className="chart-container" style={{ height: '80vh', marginBottom: '40px' }}>
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <p>Für den gewählten Datumsbereich sind keine Daten verfügbar.</p>
      )}

      {/* Controles con margen inferior */}
      <div style={{ marginBottom: '20px' }}>
        <ControlsComponent
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>
    </div>
  );
};

export default Heizoel;