import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { PolarArea, Radar } from 'react-chartjs-2';
import { FaSave } from 'react-icons/fa';
import '../styles/Social.css';
import { getLastSaveTime, getSocialData, saveSocialData } from '../utils/storage';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, RadialLinearScale, PointElement);

const Social = () => {
  const [lastSaveTime, setLastSaveTime] = useState(getLastSaveTime());
  const [employeeData, setEmployeeData] = useState({
    total: 0,
    male: 0,
    female: 0,
    diverse: 0,
    fulltime: 0,
    parttime: 0,
    apprentices: 0,
    avgAge: 0,
    avgTenure: 0,
    sickDays: 0,
    trainingHours: 0,
    turnoverRate: 0,
    departments: {},
    newDepartment: '',
    newDepartmentCount: 0
  });

  useEffect(() => {
    const savedData = getSocialData();
    if (savedData) setEmployeeData(savedData);
    setLastSaveTime(getLastSaveTime());
  }, []);

  const handleSave = () => {
    saveSocialData(employeeData);
    setLastSaveTime(getLastSaveTime());
  };



  const genderData = {
    labels: ['Männlich', 'Weiblich', 'Divers'],
    datasets: [{
      data: [employeeData.male, employeeData.female, employeeData.diverse],
      backgroundColor: ['rgba(59, 130, 246, 0.8)', 'rgba(236, 72, 153, 0.8)', 'rgba(16, 185, 129, 0.8)'],
      borderColor: ['rgba(59, 130, 246, 1)', 'rgba(236, 72, 153, 1)', 'rgba(16, 185, 129, 1)'],
      borderWidth: 1
    }]
  };

  const employmentData = {
    labels: ['Vollzeit', 'Teilzeit', 'Auszubildende'],
    datasets: [{
      data: [employeeData.fulltime, employeeData.parttime, employeeData.apprentices],
      backgroundColor: ['rgba(99, 102, 241, 0.8)', 'rgba(251, 146, 60, 0.8)', 'rgba(147, 51, 234, 0.8)'],
      borderColor: ['rgba(99, 102, 241, 1)', 'rgba(251, 146, 60, 1)', 'rgba(147, 51, 234, 1)'],
      borderWidth: 1
    }]
  };

  const departmentData = {
    labels: Object.keys(employeeData.departments),
    datasets: [{
      label: 'Mitarbeiter pro Abteilung',
      data: Object.values(employeeData.departments),
      backgroundColor: Object.keys(employeeData.departments).map((_, i) => 
        `hsla(${(i * 360) / Object.keys(employeeData.departments).length}, 70%, 60%, 0.8)`
      ),
      borderColor: Object.keys(employeeData.departments).map((_, i) => 
        `hsla(${(i * 360) / Object.keys(employeeData.departments).length}, 70%, 60%, 1)`
      ),
      borderWidth: 2
    }]
  };

  const addDepartment = () => {
    if (employeeData.newDepartment && employeeData.newDepartmentCount >= 0) {
      setEmployeeData(prev => ({
        ...prev,
        departments: {
          ...prev.departments,
          [prev.newDepartment]: prev.newDepartmentCount
        },
        newDepartment: '',
        newDepartmentCount: 0
      }));
    }
  };

  const handleInputChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setEmployeeData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: parseInt(value) || 0
        }
      }));
    } else {
      setEmployeeData(prev => ({
        ...prev,
        [field]: parseInt(value) || 0
      }));
    }
  };

  return (
    <div className="social-container">
      <motion.div 
        className="social-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Soziale Kennzahlen</h1>
        <p>Erfassen und analysieren Sie wichtige soziale Indikatoren Ihres Unternehmens</p>
      </motion.div>

      <div className="social-content">
        <motion.div 
          className="data-input-section"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="input-card">
            <h2>Mitarbeiterstruktur</h2>
            <div className="input-group">
              <label>Gesamtanzahl Mitarbeiter:innen</label>
              <input
                type="number"
                value={employeeData.total}
                onChange={(e) => handleInputChange('total', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Männliche Mitarbeiter</label>
              <input
                type="number"
                value={employeeData.male}
                onChange={(e) => handleInputChange('male', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Weibliche Mitarbeiter</label>
              <input
                type="number"
                value={employeeData.female}
                onChange={(e) => handleInputChange('female', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Diverse Mitarbeiter</label>
              <input
                type="number"
                value={employeeData.diverse}
                onChange={(e) => handleInputChange('diverse', e.target.value)}
                min="0"
              />
            </div>
          </div>
          
          <div className="input-card">
            <h2>Beschäftigungsart</h2>
            <div className="input-group">
              <label>Vollzeitmitarbeiter:innen</label>
              <input
                type="number"
                value={employeeData.fulltime}
                onChange={(e) => handleInputChange('fulltime', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Teilzeitmitarbeiter:innen</label>
              <input
                type="number"
                value={employeeData.parttime}
                onChange={(e) => handleInputChange('parttime', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Auszubildende</label>
              <input
                type="number"
                value={employeeData.apprentices}
                onChange={(e) => handleInputChange('apprentices', e.target.value)}
                min="0"
              />
            </div>
          </div>

          <div className="input-card">
            <h2>Weitere Kennzahlen</h2>
            <div className="input-group">
              <label>Durchschnittsalter (Jahre)</label>
              <input
                type="number"
                value={employeeData.avgAge}
                onChange={(e) => handleInputChange('avgAge', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Durchschnittliche Betriebszugehörigkeit (Jahre)</label>
              <input
                type="number"
                value={employeeData.avgTenure}
                onChange={(e) => handleInputChange('avgTenure', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Krankheitstage pro Mitarbeiter:in</label>
              <input
                type="number"
                value={employeeData.sickDays}
                onChange={(e) => handleInputChange('sickDays', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Weiterbildungsstunden pro Mitarbeiter:in</label>
              <input
                type="number"
                value={employeeData.trainingHours}
                onChange={(e) => handleInputChange('trainingHours', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Fluktuationsrate (%)</label>
              <input
                type="number"
                value={employeeData.turnoverRate}
                onChange={(e) => handleInputChange('turnoverRate', e.target.value)}
                min="0"
                max="100"
                step="0.1"
              />
            </div>
          </div>

          <div className="input-card">
            <h2>Abteilungen</h2>
            <div className="input-group">
              <label>Neue Abteilung</label>
              <input
                type="text"
                value={employeeData.newDepartment}
                onChange={(e) => setEmployeeData(prev => ({ ...prev, newDepartment: e.target.value }))}
                placeholder="Name der Abteilung"
              />
            </div>
            <div className="input-group">
              <label>Anzahl Mitarbeiter</label>
              <input
                type="number"
                value={employeeData.newDepartmentCount}
                onChange={(e) => setEmployeeData(prev => ({ ...prev, newDepartmentCount: parseInt(e.target.value) || 0 }))}
                min="0"
              />
            </div>
            <button className="add-department-button" onClick={addDepartment}>
              Abteilung hinzufügen
            </button>
            
            <div className="departments-list">
              {Object.entries(employeeData.departments).map(([dept, count]) => (
                <div key={dept} className="department-item">
                  <span>{dept}</span>
                  <span>{count} Mitarbeiter</span>
                  <button 
                    className="remove-department"
                    onClick={() => {
                      setEmployeeData(prev => {
                        const newDepts = { ...prev.departments };
                        delete newDepts[dept];
                        return { ...prev, departments: newDepts };
                      });
                    }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="charts-section"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="kpi-grid">
            <div className="kpi-card">
              <h3>Durchschnittsalter</h3>
              <div className="kpi-value">{employeeData.avgAge} Jahre</div>
            </div>
            <div className="kpi-card">
              <h3>Ø Betriebszugehörigkeit</h3>
              <div className="kpi-value">{employeeData.avgTenure} Jahre</div>
            </div>
            <div className="kpi-card">
              <h3>Krankheitstage</h3>
              <div className="kpi-value">{employeeData.sickDays} Tage</div>
            </div>
            <div className="kpi-card">
              <h3>Weiterbildung</h3>
              <div className="kpi-value">{employeeData.trainingHours}h</div>
            </div>
          </div>

          <div className="chart-card">
            <h2>Geschlechterverteilung</h2>
            <div className="chart-container">
              <PolarArea 
                data={genderData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        padding: 20,
                        font: {
                          size: 14
                        }
                      }
                    }
                  },
                  scales: {
                    r: {
                      ticks: {
                        display: false
                      }
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="chart-card">
            <h2>Beschäftigungsart</h2>
            <div className="chart-container">
              <PolarArea 
                data={employmentData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        padding: 20,
                        font: {
                          size: 14
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="chart-card">
            <h2>Mitarbeiter pro Abteilung</h2>
            <div className="chart-container">
              <Radar
                data={departmentData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          return `${context.label}: ${context.raw} Mitarbeiter`;
                        }
                      }
                    }
                  },
                  scales: { 
                    r: {
                      angleLines: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.1)'
                      },
                      suggestedMin: 0,
                      ticks: {
                        stepSize: 5
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div 
        className="request-feature-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <h3>Weitere soziale Kennzahlen gewünscht?</h3>
        <p>Lassen Sie uns wissen, welche zusätzlichen sozialen Indikatoren für Ihr Unternehmen relevant sind.</p>
        <input
          type="text"
          className="feature-input"
          placeholder="Name der gewünschten Kennzahl"
          id="requestedFeature"
        />
        <button
          className="feature-button"
          onClick={() => {
            const feature = document.getElementById('requestedFeature')?.value || '';
            const mailtoLink = `mailto:support@liks.gmbh?subject=Neue%20Soziale%20Kennzahl%20Anfrage&body=Sehr%20geehrtes%20LIKS-Team%2C%0A%0AIch%20möchte%20gerne%20die%20folgende%20soziale%20Kennzahl%20hinzufügen%3A%0A%0A${encodeURIComponent(feature)}%0A%0AMit%20freundlichen%20Grüßen`;
            window.location = mailtoLink;
          }}
        >
          Kennzahl vorschlagen
        </button>
      </motion.div>
      
      <div className="save-button-container">
        <button className="save-button" onClick={handleSave}>
          <FaSave /> Daten speichern
        </button>
        {lastSaveTime && (
          <div className="last-save">
            Zuletzt gespeichert: {lastSaveTime}
          </div>
        )}
      </div>
    </div>
  );
};

export default Social;