import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaChevronRight, FaExternalLinkAlt, FaIndustry, FaLeaf, FaLink, FaRecycle } from 'react-icons/fa';
import '../styles/ConversionFactors.css';

const factors = [
  {
    category: 'Scope 1 - Direkte Emissionen',
    icon: <FaIndustry />,
    factors: [
      {
        name: 'Benzin',
        value: '0.0023',
        unit: 'Tonnen CO₂ pro Liter',
        source: 'Umweltbundesamt',
        sourceUrl: 'https://www.umweltbundesamt.de/',
        description: 'Basiert auf der durchschnittlichen CO₂-Emission bei der Verbrennung von Benzin in Verbrennungsmotoren.',
        methodology: 'Berechnung basiert auf dem Kohlenstoffgehalt und der Verbrennungseffizienz von Benzin.',
      },
      {
        name: 'Diesel',
        value: '0.0027',
        unit: 'Tonnen CO₂ pro Liter',
        source: 'Umweltbundesamt',
        sourceUrl: 'https://www.umweltbundesamt.de/',
        description: 'Berücksichtigt die spezifischen CO₂-Emissionen bei der Verbrennung von Diesel.',
        methodology: 'Basiert auf dem höheren Kohlenstoffgehalt von Diesel im Vergleich zu Benzin.',
      },
      {
        name: 'Heizöl',
        value: '0.00266',
        unit: 'Tonnen CO₂ pro Liter',
        source: 'Umweltbundesamt',
        sourceUrl: 'https://www.umweltbundesamt.de/',
        description: 'CO₂-Emissionen aus der Verbrennung von Heizöl für Gebäudeheizung und industrielle Prozesse.',
        methodology: 'Berechnung basiert auf dem spezifischen Kohlenstoffgehalt von Heizöl.',
      },
      {
        name: 'Flüssiggas (LPG)',
        value: '0.0016',
        unit: 'Tonnen CO₂ pro Liter',
        source: 'International Energy Agency',
        sourceUrl: 'https://www.iea.org/',
        description: 'Emissionen aus der Verwendung von Flüssiggas für Heizung, Kochen und Fahrzeuge.',
        methodology: 'Basiert auf der chemischen Zusammensetzung und Verbrennungseffizienz von LPG.',
      }
    ]
  },
  {
    category: 'Scope 2 - Indirekte Energieemissionen',
    icon: <FaLeaf />,
    factors: [
      {
        name: 'Stromverbrauch',
        value: '0.000475',
        unit: 'Tonnen CO₂ pro kWh',
        source: 'International Energy Agency',
        sourceUrl: 'https://www.iea.org/',
        description: 'Basiert auf dem deutschen Strommix und berücksichtigt alle Energiequellen.',
        methodology: 'Gewichteter Durchschnitt der CO₂-Emissionen aller Stromquellen im deutschen Netz.',
      },
      {
        name: 'Erdgas',
        value: '0.002',
        unit: 'Tonnen CO₂ pro m³',
        source: 'International Energy Agency',
        sourceUrl: 'https://www.iea.org/',
        description: 'Berücksichtigt die Verbrennung von Erdgas in industriellen und häuslichen Anwendungen.',
        methodology: 'Berechnung basiert auf der chemischen Zusammensetzung von Erdgas und seiner Verbrennungseffizienz.',
      },
      {
        name: 'Fernwärme',
        value: '0.00032',
        unit: 'Tonnen CO₂ pro kWh',
        source: 'International Energy Agency',
        sourceUrl: 'https://www.iea.org/',
        description: 'CO₂-Emissionen aus der Erzeugung und Verteilung von Fernwärme.',
        methodology: 'Berücksichtigt den Energiemix und die Verteilungsverluste im Fernwärmenetz.',
      }
    ]
  },
  {
    category: 'Scope 3 - Sonstige indirekte Emissionen',
    icon: <FaRecycle />,
    factors: [
      {
        name: 'Wasserverbrauch',
        value: '0.000194',
        unit: 'Tonnen CO₂ pro m³',
        source: 'Water Footprint Network',
        sourceUrl: 'https://waterfootprint.org/',
        description: 'Umfasst Emissionen aus Wasseraufbereitung und -verteilung.',
        methodology: 'Berücksichtigt Energieverbrauch für Aufbereitung, Transport und Abwasserbehandlung.',
      },
      {
        name: 'Papierverbrauch',
        value: '0.0033',
        unit: 'Tonnen CO₂ pro kg',
        source: 'Environmental Paper Network',
        sourceUrl: 'https://environmentalpaper.org/',
        description: 'Berücksichtigt den gesamten Lebenszyklus von der Holzgewinnung bis zur Entsorgung.',
        methodology: 'Umfassende Analyse der CO₂-Emissionen in der Papierproduktion und -verwertung.',
      },
      {
        name: 'Geschäftsreisen (Flug)',
        value: '0.000115',
        unit: 'Tonnen CO₂ pro km',
        source: 'IATA',
        sourceUrl: 'https://www.iata.org/',
        description: 'Emissionen aus Geschäftsreisen mit dem Flugzeug pro Passagierkilometer.',
        methodology: 'Basiert auf durchschnittlichen Emissionen kommerzieller Flüge unter Berücksichtigung von Flughöhe und Treibstoffverbrauch.',
      },
      {
        name: 'Kunststoffproduktion',
        value: '0.0025',
        unit: 'Tonnen CO₂ pro kg',
        source: 'Plastics Europe',
        sourceUrl: 'https://www.plasticseurope.org/',
        description: 'CO₂-Emissionen aus der Herstellung und Verarbeitung von Kunststoffen.',
        methodology: 'Lebenszyklusanalyse von der Rohstoffgewinnung bis zur Verarbeitung.',
      },
      {
        name: 'Kältemittel R134a',
        value: '1.43',
        unit: 'Tonnen CO₂ pro kg',
        source: 'IPCC',
        sourceUrl: 'https://www.ipcc.ch/',
        description: 'Treibhauspotential des Kältemittels R134a.',
        methodology: 'GWP-Wert (Global Warming Potential) über einen Zeitraum von 100 Jahren.',
      },
      {
        name: 'Transport von Produkten',
        value: '0.00025',
        unit: 'Tonnen CO₂ pro tkm',
        source: 'International Energy Agency',
        sourceUrl: 'https://www.iea.org/',
        description: 'Emissionen aus dem Transport von Gütern pro Tonnenkilometer.',
        methodology: 'Durchschnittliche Emissionen verschiedener Transportmodi gewichtet nach Nutzung.',
      }
    ]
  }
];

const ConversionFactors = () => {
  const [selectedFactor, setSelectedFactor] = useState(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <div className="conversion-factors-container">
      <motion.div 
        className="conversion-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h1>Umrechnungsfaktoren</h1>
        <p>Umfassende Übersicht aller CO₂-Äquivalent-Umrechnungsfaktoren nach Scope-Kategorien</p>
      </motion.div>

      <motion.div
        className="conversion-content"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {factors.map((category, categoryIndex) => (
          <motion.section 
            key={categoryIndex}
            className="scope-section"
            variants={itemVariants}
          >
            <div className="scope-header">
              <div className="scope-icon">{category.icon}</div>
              <h2>{category.category}</h2>
            </div>

            <div className="factors-list-container">
              {category.factors.map((factor, factorIndex) => (
                <motion.div
                  key={factorIndex}
                  className="factor-list-item"
                  whileHover={{ backgroundColor: 'rgba(37, 99, 235, 0.05)' }}
                  onClick={() => setSelectedFactor(factor)}
                >
                  <div className="factor-main-info">
                    <div className="factor-title">
                      <h3>{factor.name}</h3>
                      <span className="factor-source">
                        <FaLink className="source-icon" />
                        {factor.source}
                      </span>
                    </div>
                    <div className="factor-values">
                      {factor.values ? (
                        <div className="agricultural-values">
                          {factor.values.map((item, idx) => (
                            <div key={idx} className="ag-value">
                              <span className="ag-label">{item.label}:</span>
                              <span className="ag-number">{item.value} {factor.unit}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="factor-value">
                          <span className="value-number">{factor.value}</span>
                          <span className="value-unit">{factor.unit}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="factor-actions">
                    <FaChevronRight className="chevron-icon" />
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.section>
        ))}
      </motion.div>

      <AnimatePresence>
        {selectedFactor && (
          <motion.div 
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedFactor(null)}
          >
            <motion.div 
              className="modal-content"
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={e => e.stopPropagation()}
            >
              <div className="modal-header">
                <h2>{selectedFactor.name}</h2>
                <button className="close-modal" onClick={() => setSelectedFactor(null)}>×</button>
              </div>
              
              <div className="modal-body">
                <div className="modal-section factor-details">
                  <h3>Umrechnungsfaktor:</h3>
                  <div className="modal-factor-value">
                    {selectedFactor.values ? (
                      <div className="agricultural-values">
                        {selectedFactor.values.map((item, idx) => (
                          <div key={idx} className="modal-ag-value">
                            <span>{item.label}:</span>
                            <strong>{item.value} {selectedFactor.unit}</strong>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="single-value">
                        <strong>{selectedFactor.value}</strong>
                        <span>{selectedFactor.unit}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="modal-section description">
                  <h3>Beschreibung:</h3>
                  <p>{selectedFactor.description}</p>
                </div>
                
                <div className="modal-section methodology">
                  <h3>Methodologie:</h3>
                  <p>{selectedFactor.methodology}</p>
                </div>
                
                <div className="modal-section source">
                  <h3>Quelle:</h3>
                  <a href={selectedFactor.sourceUrl} target="_blank" rel="noopener noreferrer">
                    {selectedFactor.source} <FaExternalLinkAlt size={12} />
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ConversionFactors;