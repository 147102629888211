// Berichterstattung.js
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import '../styles/Berichterstattung.css';

const Berichterstattung = () => {
  return (
    <div className="berichterstattung-container">
      <div className="info-card">
        <FaInfoCircle className="info-icon" />
        <h2>Information</h2>
        <p>
          Unsere Software benötigt eine Mindestmenge an Daten, um das System zur Erstellung eines ESG-Berichts zu aktivieren. Ab der Beta-Version 1.0.0, die am <strong>23.10.2024</strong> veröffentlicht wird, steht die Erstellung von Berichten jederzeit zur Verfügung. Die Schnittstelle wird aktualisiert, um Ihnen ein verbessertes Erlebnis zu bieten.
        </p>
        <p>
          Bitte stellen Sie sicher, dass Sie genügend Daten hochgeladen haben, damit das System ordnungsgemäß funktioniert. Wenn Sie Fragen haben, wenden Sie sich bitte an unser Support-Team.
        </p>
      </div>
    </div>
  );
};

export default Berichterstattung;