// src/components/ChartComponent.js
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
CategoryScale,
LinearScale,
PointElement,
LineElement,
BarElement,
Title,
Tooltip,
Legend,
Filler,
annotationPlugin
);

const ChartComponent = ({
data,
options,
chartRef,
chartType,
showReferenceLine,
zielConsumption,
zielCO2,
zielDate,
}) => {
const zielDateStr = zielDate ? zielDate.toLocaleDateString('de-DE') : '';



// Ajustar las opciones del gráfico
const adjustedOptions = {
  ...options,
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    ...options.plugins, 
    title: {
      display: true,
      font: {
        size: 24,
        family: "'Inter', sans-serif",
        weight: '600'
      },
      color: '#111827',
      padding: {
        top: 20,
        bottom: 20
      }
    },
    datalabels: {
      display: false, // Desactiva las etiquetas de datos
    },
    
    legend: {
      display: true, // Mostrar la leyenda para permitir activar/desactivar datasets
      position: 'bottom',
      labels: {
        font: {
          family: "'Inter', sans-serif",
          size: 13,
          weight: '500'
        },
        color: '#4B5563',
        usePointStyle: true,
        padding: 20,
      },
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      titleColor: '#111827',
      bodyColor: '#4B5563',
      borderColor: '#E5E7EB',
      borderWidth: 1,
      cornerRadius: 8,
      padding: 12,
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      titleFont: {
        family: "'Inter', sans-serif",
        size: 13,
        weight: '600'
      },
      bodyFont: {
        family: "'Inter', sans-serif",
        size: 12,
        weight: '400'
      },
      callbacks: {
        label: function (context) {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          return `${label}: ${value.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`;
        },
      },
    },
    annotation: showReferenceLine
      ? {
          annotations: {
            line1: {
              type: 'line',
              xMin: zielDateStr,
              xMax: zielDateStr,
              yMin: zielConsumption,
              yMax: zielConsumption,
              borderColor: '#007AFF', // Azul característico de Apple
              borderWidth: 2,
              label: {
                enabled: false, // Desactiva la etiqueta de la línea
              },
            },
            line2: {
              type: 'line',
              xMin: zielDateStr,
              xMax: zielDateStr,
              yMin: zielCO2,
              yMax: zielCO2,
              borderColor: '#34C759', // Verde característico de Apple
              borderWidth: 2,
              label: {
                enabled: false, // Desactiva la etiqueta de la línea
              },
            },
          },
        }
      : {},
  },
  elements: {
    line: {
      tension: 0.4, // Líneas suaves
      borderWidth: 2.5,
    },
    point: {
      radius: 0,
      hitRadius: 8,
      hoverRadius: 8,
      backgroundColor: '#fff',
      borderWidth: 2.5,
      hoverBorderWidth: 3,
    },
    bar: {
      borderRadius: 6,
      borderSkipped: false,
    }
  },
  scales: {
    x: {
      grid: {
        display: false, // Elimina las líneas de la cuadrícula en el eje X
      },
      ticks: {
        font: {
          family: "'Inter', sans-serif",
          size: 12,
          weight: '500'
        },
        color: '#6B7280',
        padding: 8
      },
    },
    y: {
      grid: {
        borderDash: [4, 4],
        color: '#E5E7EB',
        drawBorder: false
      },
      ticks: {
        font: {
          family: "'Inter', sans-serif",
          size: 12,
          weight: '500'
        },
        color: '#6B7280',
        padding: 8,
        beginAtZero: true,
        callback: function (value) {
          return value.toLocaleString('de-DE');
        },
      },
    },
  },

  interaction: {
    mode: 'index',
    intersect: false
  },
  animation: {
    duration: 750,
    easing: 'easeInOutQuart'
  }
};

const Chart = chartType === 'bar' ? Bar : Line;

return (
  <div className="chart-wrapper">
    <Chart 
      ref={chartRef} 
      data={data} 
      options={adjustedOptions}
      type={chartType}
    />
  </div>
);
};

export default ChartComponent;
