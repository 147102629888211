// DataContent.js

import React from 'react';
import { FaBolt, FaLeaf, FaRecycle } from 'react-icons/fa'; // Iconos modernos y minimalistas
import '../styles/DataContent.css';

const faktoren = [
    // Scope 1
    { name: 'Benzinverbrauch', key: 'benzinverbrauch', scope: 'Scope 1' },
    { name: 'Dieselverbrauch', key: 'dieselverbrauch', scope: 'Scope 1' },
    { name: 'Heizölverbrauch', key: 'heizoel', scope: 'Scope 1' },

    // Scope 2
    { name: 'Stromverbrauch', key: 'elektrizitaet', scope: 'Scope 2' },
    { name: 'Nutzung erneuerbarer Energien', key: 'nutzung-erneuerbarer-energien', scope: 'Scope 2' },

    // Scope 3
    { name: 'Erzeugte Abfälle', key: 'erzeugte-abfaelle', scope: 'Scope 3' },
    { name: 'Papierverbrauch', key: 'papierverbrauch', scope: 'Scope 3' },
    { name: 'Geschäftsreisen (Flugreisen)', key: 'geschaeftsreisen', scope: 'Scope 3' },
    { name: 'Verbrauch von landwirtschaftlichen Produkten', key: 'verbrauch-landwirtschaftliche-produkte', scope: 'Scope 3' },
    // Puedes agregar más factores aquí
];

/**
 * Agrupa los factores por su Scope ESG.
 * @param {Array} faktoren - Lista de factores.
 * @returns {Object} - Factores agrupados por Scope.
 */
const gruppierenNachScope = (faktoren) => {
    return faktoren.reduce((acc, faktor) => {
        if (!acc[faktor.scope]) {
            acc[faktor.scope] = [];
        }
        acc[faktor.scope].push(faktor);
        return acc;
    }, {});
};

/**
 * Obtiene el icono correspondiente al Scope ESG.
 * @param {string} scope - El Scope ESG.
 * @returns {JSX.Element|null} - Icono correspondiente o null si no hay.
 */
const erhalteIconFürScope = (scope) => {
    switch (scope) {
        case 'Scope 1':
            return <FaLeaf className="scope-icon" />;
        case 'Scope 2':
            return <FaBolt className="scope-icon" />;
        case 'Scope 3':
            return <FaRecycle className="scope-icon" />;
        default:
            return null;
    }
};

/**
 * Componente principal que muestra los factores agrupados por Scope ESG.
 * @param {Function} onNavigate - Función para manejar la navegación al hacer clic en una tarjeta.
 * @returns {JSX.Element|null} - Renderiza la interfaz o null si falta onNavigate.
 */
const DataContent = ({ onNavigate }) => {
    if (!onNavigate) {
        console.error('onNavigate wurde nicht als Prop an DataContent übergeben.');
        return null; // Alternativ: algún contenido de reserva
    }

    // Agrupar los factores por Scope
    const gruppierteFaktoren = gruppierenNachScope(faktoren);

    return (
        <div className="data-content-container">
            {Object.keys(gruppierteFaktoren).map((scope) => (
                <div key={scope} className="scope-section">
                    {/* Tarjeta de encabezado para el Scope */}
                    <div className="scope-header-card">
                        {erhalteIconFürScope(scope)}
                        <h2>{scope}</h2>
                    </div>
                    <div className="cards-container">
                        {gruppierteFaktoren[scope].map((faktor) => (
                            <div
                                key={faktor.key}
                                className="card"
                                onClick={() => onNavigate(faktor.key)}
                            >
                                <div className="card-body">
                                    <span>{faktor.name}</span>
                                    <svg
                                        className="chevron-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path fillRule="evenodd" d="M6.646 11.854a.5.5 0 0 1-.708-.708L10.293 8 5.938 3.854a.5.5 0 1 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5z" />
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DataContent;
