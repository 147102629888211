import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaTools } from 'react-icons/fa';

// Importa componentes y páginas
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AIchat from './pages/AIchat';
import Analyse from './pages/Analyse';
import Benzinverbrauch from './pages/Benzinverbrauch';
import Berichte from './pages/Berichte';
import Berichterstattung from './pages/Berichterstattung';
import ConversionFactors from './pages/ConversionFactors';
import Dashboard from './pages/Dashboard';
import DataContent from './pages/DataContent';
import Dieselverbrauch from './pages/dieselverbrauch';
import Documentation from './pages/Documentation';
import Elektrizitaet from './pages/Elektrizitaet';
import Environmental from './pages/Environmental';
import Erdgas from './pages/Erdgas';
import ErzeugteAbfaelle from './pages/ErzeugteAbfaelle';
import GesamtEmissionen from './pages/GesamtEmissionen';
import Geschaeftsreisen from './pages/Geschaeftsreisen';
import GlobaleEinstellungen from './pages/GlobaleEinstellungen';
import Governance from './pages/Governance';
import Heizoel from './pages/Heizoel';
import Impressum from './pages/impressum';
import Kaeltemittel from './pages/Kaeltemittel';
import Kunststoffproduktion from './pages/Kunststoffproduktion';
import Papierverbrauch from './pages/Papierverbrauch';
import Produktionsemissionen from './pages/Produktionsemissionen';
import Scope1 from './pages/Scope1';
import Scope2 from './pages/Scope2';
import Scope3 from './pages/Scope3';
import Social from './pages/Social';
import TransportProdukte from './pages/TransportProdukte';
import Ubersicht from './pages/Ubersicht';
import VerbrauchLandwirtschaftlicheProdukte from './pages/VerbrauchLandwirtschaftlicheProdukte';
import Wasserverbrauch from './pages/Wasserverbrauch';
import Wesentlichkeitsanalyse from './pages/wesentlichkeitsanalyse';

import './styles/App.css'; // Importa estilos globales
import './styles/sidebar.css'; // Importa estilos del Sidebar

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0(); // Autenticación de Auth0
  const [isUpdating] = useState(false); // Estado para controlar la pantalla de actualización
  const [activePage, setActivePage] = useState('dashboard'); // Estado para la página activa
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para la visibilidad de la Sidebar

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect(); // Redirige al login si no está autenticado
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // Función para renderizar el contenido según la página activa
  const renderContent = () => {
    switch (activePage) {
      case 'dashboard':
        return <Dashboard onNavigate={setActivePage} />;
      case 'settings':
        return <GlobaleEinstellungen />;
      case 'berichterstattung':
        return <Berichterstattung onNavigate={setActivePage} />;
      case 'berichte':
        return <Berichte onNavigate={setActivePage} />;
      case 'wesentlichkeitsanalyse':
        return <Wesentlichkeitsanalyse onNavigate={setActivePage} />;
      case 'environmental':
        return <Environmental onNavigate={setActivePage} />;
      case 'social':
        return <Social onNavigate={setActivePage} />;
      case 'governance':
        return <Governance onNavigate={setActivePage} />;
      case 'data':
        return <DataContent onNavigate={setActivePage} />;
      case 'benzinverbrauch':
        return <Benzinverbrauch onNavigate={setActivePage} />;
      case 'dieselverbrauch':
        return <Dieselverbrauch onNavigate={setActivePage} />;
      case 'elektrizitaet':
        return <Elektrizitaet onNavigate={setActivePage} />;
      case 'erdgas':
        return <Erdgas onNavigate={setActivePage} />;
      case 'heizoel':
        return <Heizoel onNavigate={setActivePage} />;
      case 'wasserverbrauch':
        return <Wasserverbrauch onNavigate={setActivePage} />;
      case 'erzeugte-abfaelle':
        return <ErzeugteAbfaelle onNavigate={setActivePage} />;
      case 'geschaeftsreisen':
        return <Geschaeftsreisen onNavigate={setActivePage} />;
      case 'papierverbrauch':
        return <Papierverbrauch onNavigate={setActivePage} />;
      case 'kunststoffproduktion':
        return <Kunststoffproduktion onNavigate={setActivePage} />;
      case 'kaeltemittel':
        return <Kaeltemittel onNavigate={setActivePage} />;
      case 'transport-produkte':
        return <TransportProdukte onNavigate={setActivePage} />;
      case 'verbrauch-landwirtschaftliche-produkte':
        return <VerbrauchLandwirtschaftlicheProdukte onNavigate={setActivePage} />;
      case 'produktionsemissionen':
        return <Produktionsemissionen onNavigate={setActivePage} />;
      case 'gesamt-emissionen':
        return <GesamtEmissionen onNavigate={setActivePage} />;
      case 'documentation':
        return <Documentation onNavigate={setActivePage} />;
      case 'analyse':
        return <Analyse onNavigate={setActivePage} />;
      case 'aichat':
        return <AIchat onNavigate={setActivePage} />;
      case 'impressum':
        return <Impressum onNavigate={setActivePage} />;
      case 'scope1':
        return <Scope1 onNavigate={setActivePage} />;
      case 'scope2':
        return <Scope2 onNavigate={setActivePage} />;
      case 'scope3':
        return <Scope3 onNavigate={setActivePage} />;
      case 'ubersicht':
        return <Ubersicht onNavigate={setActivePage} />;
      case 'conversionfactors':
        return <ConversionFactors onNavigate={setActivePage} />;
      
      default:
        return <Dashboard onNavigate={setActivePage} />;
    }
  };

  // Mostrar un spinner mientras se carga la autenticación
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  // Mostrar pantalla de actualización si está en mantenimiento
  if (isAuthenticated && isUpdating) {
    return (
      <div className="update-screen d-flex justify-content-center align-items-center">
        <div className="text-center">
          {/* Icono de mantenimiento */}
          <FaTools size={50} color="#343a40" className="mb-4" />
          {/* Mensajes de actualización */}
          <h2>Hallo,</h2>
          <p>Wir führen ein Systemupdate durch, um dir eine bessere Erfahrung zu bieten.</p>
          <p>Wir senden dir eine E-Mail, sobald das Update abgeschlossen ist.</p>
          <p>Vielen Dank für deine Geduld.</p>
          {/* Información de soporte */}
          <p className="fw-bold">Support LIKS</p>
          <p>
            Kontaktiere uns: <a href="mailto:support@liks.ai">support@liks.ai</a>
          </p>
        </div>
      </div>
    );
  }

  // Determinar si el Sidebar debe ser visible
  const isSidebarVisible = activePage !== 'dashboard';

  // Renderizar la aplicación principal
  return (
    <div className="App">
      {isAuthenticated && (
        <>
          <Header
            toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
            activePage={activePage}
          />

          {/* Mostrar Sidebar si está visible */}
          {isSidebarVisible && (
            <Sidebar
              activePage={activePage}
              onSelectPage={(page) => {
                setActivePage(page);
                if (window.innerWidth < 576) {
                  setIsSidebarOpen(false);
                }
              }}
              isOpen={isSidebarOpen}
              closeSidebar={() => setIsSidebarOpen(false)}
            />
          )}

          {/* Área de contenido */}
          <div className={`content-area ${isSidebarVisible ? 'with-sidebar' : ''}`}>
            {renderContent()}
          </div>
        </>
      )}
    </div>
  );
}

export default App;