// storage.js
const STORAGE_KEYS = {
    SOCIAL: 'liks_social_data',
    GOVERNANCE: 'liks_governance_data',
    LAST_SAVE: 'liks_last_save_timestamp',
    LAST_SAVE_TIME: 'liks_last_save_time'
  };
  
  export const saveSocialData = (data) => {
    try {
      localStorage.setItem(STORAGE_KEYS.SOCIAL, JSON.stringify(data));
      localStorage.setItem(STORAGE_KEYS.LAST_SAVE, Date.now().toString());
      const now = new Date();
      localStorage.setItem(STORAGE_KEYS.LAST_SAVE_TIME, now.toLocaleString('de-DE'));
      return true;
    } catch (error) {
      console.error('Error saving social data:', error);
      return false;
    }
  };
  
  export const getSocialData = () => {
    try {
      const data = localStorage.getItem(STORAGE_KEYS.SOCIAL);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error getting social data:', error);
      return null;
    }
  };
  
  export const saveGovernanceData = (data) => {
    try {
      localStorage.setItem(STORAGE_KEYS.GOVERNANCE, JSON.stringify(data));
      localStorage.setItem(STORAGE_KEYS.LAST_SAVE, Date.now().toString());
      const now = new Date();
      localStorage.setItem(STORAGE_KEYS.LAST_SAVE_TIME, now.toLocaleString('de-DE'));
      return true;
    } catch (error) {
      console.error('Error saving governance data:', error);
      return false;
    }
  };
  
  export const getLastSaveTime = () => {
    try {
      return localStorage.getItem(STORAGE_KEYS.LAST_SAVE_TIME) || null;
    } catch (error) {
      console.error('Error getting last save time:', error);
      return null;
    }
  };
  
  export const getGovernanceData = () => {
    try {
      const data = localStorage.getItem(STORAGE_KEYS.GOVERNANCE);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error getting governance data:', error);
      return null;
    }
  };