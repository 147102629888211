import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Bar, PolarArea, Radar } from 'react-chartjs-2';
import { FaSave } from 'react-icons/fa';
import '../styles/Governance.css';
import { getGovernanceData, getLastSaveTime, saveGovernanceData } from '../utils/storage';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, RadialLinearScale, PointElement);

const Governance = () => {
  const [lastSaveTime, setLastSaveTime] = useState(getLastSaveTime());
  const [governanceData, setGovernanceData] = useState({
    boardMembers: {
      total: 0,
      independent: 0,
      female: 0,
      diverse: 0
    },
    committees: {
      audit: 0,
      compensation: 0,
      nomination: 0,
      risk: 0,
      sustainability: 0
    },
    compliance: {
      trainingHours: 0,
      incidents: 0,
      resolutionRate: 0,
      certifications: 0
    },
    riskManagement: {
      assessments: 0,
      mitigationRate: 0,
      reviews: 0
    },
    shareholderEngagement: {
      meetings: 0,
      votingRate: 0,
      resolutions: 0
    }
  });

  useEffect(() => {
    const savedData = getGovernanceData();
    if (savedData) setGovernanceData(savedData);
    setLastSaveTime(getLastSaveTime());
  }, []);

  const handleSave = () => {
    saveGovernanceData(governanceData);
  };

  const boardCompositionData = {
    labels: ['Unabhängige', 'Weiblich', 'Divers', 'Andere'],
    datasets: [{
      data: [
        governanceData.boardMembers.independent,
        governanceData.boardMembers.female,
        governanceData.boardMembers.diverse,
        Math.max(0, governanceData.boardMembers.total - 
          (governanceData.boardMembers.independent + 
           governanceData.boardMembers.female + 
           governanceData.boardMembers.diverse))
      ],
      backgroundColor: [
        'rgba(59, 130, 246, 0.8)',
        'rgba(236, 72, 153, 0.8)',
        'rgba(16, 185, 129, 0.8)',
        'rgba(107, 114, 128, 0.8)'
      ],
      borderColor: [
        'rgba(59, 130, 246, 1)',
        'rgba(236, 72, 153, 1)',
        'rgba(16, 185, 129, 1)',
        'rgba(107, 114, 128, 1)'
      ],
      borderWidth: 1
    }]
  };

  const committeesData = {
    labels: ['Audit', 'Vergütung', 'Nominierung', 'Risiko', 'Nachhaltigkeit'],
    datasets: [{
      label: 'Anzahl Mitglieder',
      data: [
        governanceData.committees.audit,
        governanceData.committees.compensation,
        governanceData.committees.nomination,
        governanceData.committees.risk,
        governanceData.committees.sustainability
      ],
      backgroundColor: [
        'rgba(99, 102, 241, 0.8)',
        'rgba(251, 146, 60, 0.8)',
        'rgba(147, 51, 234, 0.8)',
        'rgba(239, 68, 68, 0.8)',
        'rgba(16, 185, 129, 0.8)'
      ],
      borderWidth: 1
    }]
  };

  const complianceData = {
    labels: ['Schulungsstunden', 'Vorfälle', 'Lösungsrate', 'Zertifizierungen'],
    datasets: [{
      data: [
        governanceData.compliance.trainingHours,
        governanceData.compliance.incidents,
        governanceData.compliance.resolutionRate,
        governanceData.compliance.certifications
      ],
      backgroundColor: [
        'rgba(59, 130, 246, 0.6)',
        'rgba(239, 68, 68, 0.6)',
        'rgba(16, 185, 129, 0.6)',
        'rgba(251, 146, 60, 0.6)'
      ],
      borderWidth: 0
    }]
  };

  const handleInputChange = (category, field, value) => {
    setGovernanceData(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: Number(value)
      }
    }));
  };

  return (
    <div className="governance-container">
      <motion.div 
        className="governance-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Governance Kennzahlen</h1>
        <p>Erfassen und analysieren Sie wichtige Governance-Indikatoren Ihres Unternehmens</p>
      </motion.div>

      <div className="governance-content">
        <motion.div 
          className="data-input-section"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="input-card">
            <h2>Vorstandsstruktur</h2>
            <div className="input-group">
              <label>Gesamtanzahl Vorstandsmitglieder</label>
              <input
                type="number"
                value={governanceData.boardMembers.total}
                onChange={(e) => handleInputChange('boardMembers', 'total', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Unabhängige Mitglieder</label>
              <input
                type="number"
                value={governanceData.boardMembers.independent}
                onChange={(e) => handleInputChange('boardMembers', 'independent', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Weibliche Mitglieder</label>
              <input
                type="number"
                value={governanceData.boardMembers.female}
                onChange={(e) => handleInputChange('boardMembers', 'female', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Diverse Mitglieder</label>
              <input
                type="number"
                value={governanceData.boardMembers.diverse}
                onChange={(e) => handleInputChange('boardMembers', 'diverse', e.target.value)}
                min="0"
              />
            </div>
          </div>

          <div className="input-card">
            <h2>Ausschüsse</h2>
            <div className="input-group">
              <label>Prüfungsausschuss</label>
              <input
                type="number"
                value={governanceData.committees.audit}
                onChange={(e) => handleInputChange('committees', 'audit', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Vergütungsausschuss</label>
              <input
                type="number"
                value={governanceData.committees.compensation}
                onChange={(e) => handleInputChange('committees', 'compensation', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Nominierungsausschuss</label>
              <input
                type="number"
                value={governanceData.committees.nomination}
                onChange={(e) => handleInputChange('committees', 'nomination', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Risikoausschuss</label>
              <input
                type="number"
                value={governanceData.committees.risk}
                onChange={(e) => handleInputChange('committees', 'risk', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Nachhaltigkeitsausschuss</label>
              <input
                type="number"
                value={governanceData.committees.sustainability}
                onChange={(e) => handleInputChange('committees', 'sustainability', e.target.value)}
                min="0"
              />
            </div>
          </div>

          <div className="input-card">
            <h2>Compliance & Risikomanagement</h2>
            <div className="input-group">
              <label>Compliance-Schulungsstunden</label>
              <input
                type="number"
                value={governanceData.compliance.trainingHours}
                onChange={(e) => handleInputChange('compliance', 'trainingHours', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Compliance-Vorfälle</label>
              <input
                type="number"
                value={governanceData.compliance.incidents}
                onChange={(e) => handleInputChange('compliance', 'incidents', e.target.value)}
                min="0"
              />
            </div>
            <div className="input-group">
              <label>Lösungsrate (%)</label>
              <input
                type="number"
                value={governanceData.compliance.resolutionRate}
                onChange={(e) => handleInputChange('compliance', 'resolutionRate', e.target.value)}
                min="0"
                max="100"
              />
            </div>
            <div className="input-group">
              <label>Aktive Zertifizierungen</label>
              <input
                type="number"
                value={governanceData.compliance.certifications}
                onChange={(e) => handleInputChange('compliance', 'certifications', e.target.value)}
                min="0"
              />
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="charts-section"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="kpi-grid">
            <div className="kpi-card">
              <h3>Vorstandsgröße</h3>
              <div className="kpi-value">{governanceData.boardMembers.total}</div>
            </div>
            <div className="kpi-card">
              <h3>Unabhängigkeitsquote</h3>
              <div className="kpi-value">
                {governanceData.boardMembers.total ? 
                  `${Math.round((governanceData.boardMembers.independent / governanceData.boardMembers.total) * 100)}%` : 
                  '0%'}
              </div>
            </div>
            <div className="kpi-card">
              <h3>Frauenquote</h3>
              <div className="kpi-value">
                {governanceData.boardMembers.total ? 
                  `${Math.round((governanceData.boardMembers.female / governanceData.boardMembers.total) * 100)}%` : 
                  '0%'}
              </div>
            </div>
            <div className="kpi-card">
              <h3>Compliance-Rate</h3>
              <div className="kpi-value">{governanceData.compliance.resolutionRate}%</div>
            </div>
          </div>

          <div className="chart-card">
            <h2>Vorstandszusammensetzung</h2>
            <div className="chart-container">
              <PolarArea 
                data={boardCompositionData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        padding: 20,
                        font: {
                          size: 14
                        }
                      }
                    }
                  },
                  scales: {
                    r: {
                      ticks: {
                        display: false
                      }
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="chart-card">
            <h2>Ausschussstruktur</h2>
            <div className="chart-container">
              <Bar
                data={committeesData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1
                      }
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="chart-card">
            <h2>Compliance-Metriken</h2>
            <div className="chart-container">
              <Radar
                data={complianceData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  scales: {
                    r: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 2
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div 
        className="request-feature-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <h3>Weitere Governance Kennzahlen gewünscht?</h3>
        <p>Lassen Sie uns wissen, welche zusätzlichen Governance-Indikatoren für Ihr Unternehmen relevant sind.</p>
        <input
          type="text"
          className="feature-input"
          placeholder="Name der gewünschten Kennzahl"
          id="requestedFeature"
        />
        <button
          className="feature-button"
          onClick={() => {
            const feature = document.getElementById('requestedFeature')?.value || '';
            const mailtoLink = `mailto:support@liks.gmbh?subject=Neue%20Governance%20Kennzahl%20Anfrage&body=Sehr%20geehrtes%20LIKS-Team%2C%0A%0AIch%20möchte%20gerne%20die%20folgende%20Governance%20Kennzahl%20hinzufügen%3A%0A%0A${encodeURIComponent(feature)}%0A%0AMit%20freundlichen%20Grüßen`;
            window.location = mailtoLink;
          }}
        >
          Kennzahl vorschlagen
        </button>
      </motion.div>
      
      <div className="save-button-container">
        <button className="save-button" onClick={handleSave}>
          <FaSave /> Daten speichern
        </button>
        {lastSaveTime && (
          <div className="last-save">
            Zuletzt gespeichert: {lastSaveTime}
          </div>
        )}
      </div>
    </div>
  );
};

export default Governance;