import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import de from 'date-fns/locale/de';
import { motion } from 'framer-motion';
import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Dropdown, Form, ListGroup, Modal, ProgressBar, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import '../styles/Analyse.css';

registerLocale('de', de);

const Analyse = ({ onNavigate }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [, setUploadProgress] = useState(0);
  const [analysisProgress, setAnalysisProgress] = useState(0);
  const [skippedFiles, setSkippedFiles] = useState([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [start_datum, setstart_datum] = useState(null);
  const [end_datum, setend_datum] = useState(null);
  const fileInputRef = useRef(null);
  const tableRef = useRef(null);
  const [, setFilesProcessed] = useState(0);
  const [, setTotalFiles] = useState(0);
  const [selectedFactors, setSelectedFactors] = useState([]);
  const [analyzedFileList, setAnalyzedFileList] = useState([]);
  const [filesRequiringConfirmation, setFilesRequiringConfirmation] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showFileBanner, setShowFileBanner] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [showBemerkungenModal, setShowBemerkungenModal] = useState(false);
  const [selectedBemerkungen, setSelectedBemerkungen] = useState('');

  const factors = [
    "benzin",
    "diesel",
    "stromverbrauch",
    "erdgas",
    "erzeugte_abfaelle",
    "kaeltemittel",
    "wasserverbrauch",
    "papierverbrauch"
  ];

  const formatDate = (dateInput) => {
    if (!dateInput || dateInput === '') return '';
    try {
      const date = parseISO(dateInput);
      if (isNaN(date)) {
        console.warn('Ungültiges Datum:', dateInput);
        return '';
      }
      return format(date, 'dd.MM.yy');
    } catch (error) {
      console.warn('Fehler beim Formatieren des Datums:', dateInput, error);
      return '';
    }
  };

  const fetchAnalysisResults = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Fehler beim Abrufen der Analyseergebnisse:', error);
    }
  }, []);

  const fetchFileList = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/files/`);
      setFileList(response.data.files);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dateiliste:', error);
    }
  };

  const monitorAnalysisProgress = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-status/`);
      if (response.data.analyzing) {
        const progress = (response.data.files_processed / response.data.total_files) * 100;
        setAnalysisProgress(progress);
        setFilesProcessed(response.data.files_processed);
        setTotalFiles(response.data.total_files);
        setTimeout(monitorAnalysisProgress, 1000);
      } else {
        setAnalyzing(false);
        setAnalysisProgress(0);
        setFilesProcessed(0);
        setTotalFiles(0);
      }
    } catch (error) {
      console.error('Fehler beim Überwachen des Analysefortschritts:', error);
    }
  }, []);

  const checkAnalysisStatus = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-status/`);
      if (response.data.analyzing) {
        setAnalyzing(true);
        const progress = (response.data.files_processed / response.data.total_files) * 100;
        setAnalysisProgress(progress);
        setFilesProcessed(response.data.files_processed);
        setTotalFiles(response.data.total_files);
        monitorAnalysisProgress();
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Analysezustands:', error);
    }
  }, [monitorAnalysisProgress]);

  const fetchAnalyzedFileList = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analyzed-files/`);
      setAnalyzedFileList(response.data.files);
    } catch (error) {
      console.error('Fehler beim Abrufen der analysierten Dateiliste:', error);
    }
  }, []);

  useEffect(() => {
    fetchFileList();
    fetchAnalysisResults();
    fetchAnalyzedFileList();
    checkAnalysisStatus();
  }, [fetchAnalysisResults, checkAnalysisStatus, fetchAnalyzedFileList]);

  const processSelectedFiles = useCallback((files) => {
    const filesInUploaded = [];
    const filesInAnalyzed = [];
    const filesToUpload = [];

    files.forEach((file) => {
      if (fileList.includes(file.name)) {
        filesInUploaded.push(file.name);
      } else if (analyzedFileList.includes(file.name)) {
        filesInAnalyzed.push(file);
      } else {
        filesToUpload.push(file);
      }
    });

    if (filesInUploaded.length > 0) {
      setSkippedFiles(filesInUploaded);
    }

    if (filesInAnalyzed.length > 0) {
      setFilesRequiringConfirmation(filesInAnalyzed);
      setShowConfirmationModal(true);
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...filesToUpload]);
    if (setUploadProgress) {
      setUploadProgress(0);
    }
  }, [fileList, analyzedFileList]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    processSelectedFiles(newFiles);
  };

  const onDrop = useCallback((acceptedFiles) => {
    processSelectedFiles(acceptedFiles);
  }, [processSelectedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const confirmReupload = () => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesRequiringConfirmation]);
    setFilesRequiringConfirmation([]);
    setShowConfirmationModal(false);
  };

  const declineReupload = () => {
    setFilesRequiringConfirmation([]);
    setShowConfirmationModal(false);
  };

  const handleFileUpload = async () => {
    if (analyzing) {
      setUploadMessage('Während einer Analyse können keine Dateien hochgeladen werden.');
      return;
    }

    if (selectedFiles.length === 0) {
      setUploadMessage('Es wurden keine Dateien ausgewählt.');
      return;
    }

    const filesArray = Array.from(selectedFiles);
    const chunkSize = 20;
    let totalUploaded = 0;
    let totalFilesToUpload = filesArray.length;

    for (let i = 0; i < filesArray.length; i += chunkSize) {
      const chunk = filesArray.slice(i, i + chunkSize);
      const formData = new FormData();
      chunk.forEach(file => formData.append('files', file));

      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.post(`${backendUrl}/analyse/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.status === 200) {
          const { uploaded_files = [], skipped_files = [] } = response.data;
          let uploadMsg = '';

          if (uploaded_files.length > 0) {
            uploadMsg += 'Dateien erfolgreich hochgeladen. ';
          }

          if (skipped_files.length > 0) {
            uploadMsg += `${skipped_files.length} Datei(en) wurden übersprungen, da sie bereits vorhanden waren.`;
            setSkippedFiles(skipped_files);
          } else {
            setSkippedFiles([]);
          }

          setUploadMessage(uploadMsg.trim());
          totalUploaded += chunk.length;

          const progress = (totalUploaded / totalFilesToUpload) * 100;
          setUploadProgress(progress);

          await fetchFileList();
          await fetchAnalysisResults();
          await fetchAnalyzedFileList();
        }
      } catch (error) {
        setUploadMessage('Fehler beim Hochladen der Dateien.');
        console.error('Fehler beim Hochladen der Dateien:', error);
      }
    }

    setUploadProgress(0);
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleAnalyzeAndUpdate = useCallback(async () => {
    if (analyzing) {
      setUploadMessage('Es läuft bereits eine Analyse.');
      return;
    }

    setAnalyzing(true);
    setAnalysisProgress(0);

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const filesResponse = await axios.get(`${backendUrl}/analyse/files/`);
      const totalFilesToProcess = filesResponse.data.files.length;
      setTotalFiles(totalFilesToProcess);

      if (totalFilesToProcess === 0) {
        setUploadMessage('Es gibt keine Dateien, die auf eine Analyse warten.');
        setAnalyzing(false);
        return;
      }

      const response = await fetch(`${backendUrl}/analyse/analyze-update`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Fehler bei der Analyseanforderung.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        for (let line of lines) {
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.replace('data: ', ''));

            if (data.status === 'complete') {
              setAnalyzing(false);
              break;
            }

            if (data.status === 'error') {
              console.error(`Fehler in der Datei ${data.file}: ${data.message}`);
            }

            setFilesProcessed(data.files_processed);
            const progress = (data.files_processed / data.total_files) * 100;
            setAnalysisProgress(progress);

            setFileList(prevFiles => prevFiles.filter(file => file !== data.file));

            fetchAnalysisResults();
            fetchAnalyzedFileList();

            if (progress >= 100) {
              setAnalyzing(false);
            }
          }
        }
      }
    } catch (error) {
      console.error('Fehler während der Analyse und Aktualisierung:', error);
      setAnalyzing(false);
      setUploadMessage('Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  }, [analyzing, fetchAnalysisResults, fetchAnalyzedFileList]);

  const getSortDate = (result) => {
    if (result.date && result.date !== '') {
      return new Date(result.date);
    } else if (result.start_date && result.start_date !== '') {
      return new Date(result.start_date);
    } else {
      return new Date(0);
    }
  };

  const sortedResults = [...analysisResults].sort((a, b) => getSortDate(a) - getSortDate(b));

  const filteredResults = sortedResults.filter((result) => {
    let resultDate = null;
    if (result.date && result.date !== '') {
      resultDate = new Date(result.date);
    } else if (result.start_date && result.start_date !== '') {
      resultDate = new Date(result.start_date);
    }

    let isAfterStart = true;
    let isBeforeEnd = true;

    if (start_datum && resultDate) {
      isAfterStart = resultDate >= start_datum;
    }

    if (end_datum && resultDate) {
      isBeforeEnd = resultDate <= end_datum;
    }

    const isFactorSelected = selectedFactors.length > 0 ? selectedFactors.includes(result.factor) : true;
    return isAfterStart && isBeforeEnd && isFactorSelected;
  });

  const csvData = filteredResults.map(({ date, start_date, end_date, factor, value, co2_aequivalent, bemerkungen }) => ({
    Datum: date && date !== '' ? formatDate(date) : (start_date && start_date !== '' && end_date && end_date !== '' ? `${formatDate(start_date)} - ${formatDate(end_date)}` : ''),
    Faktor: factor,
    Wert: value,
    'CO₂-Äquivalent': co2_aequivalent.toFixed(4),
    Bemerkungen: bemerkungen,
  }));

  const exportTableAsImage = async () => {
    if (tableRef.current) {
      try {
        const canvas = await html2canvas(tableRef.current);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'analyse_ergebnisse.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Fehler beim Exportieren der Tabelle als Bild:', error);
      }
    }
  };

  const handleDeleteResult = async (id) => {
    if (window.confirm('Sind Sie sicher, dass Sie dieses Ergebnis löschen möchten?')) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.delete(`${backendUrl}/analyse/analysis-results/${id}/`);
        await fetchAnalysisResults();
      } catch (error) {
        console.error('Fehler beim Löschen des Ergebnisses:', error);
        alert('Fehler beim Löschen des Ergebnisses. Bitte versuchen Sie es erneut.');
      }
    }
  };

  const handleDeletePendingFile = async (filename) => {
    if (window.confirm(`Sind Sie sicher, dass Sie die Datei "${filename}" löschen möchten?`)) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.delete(`${backendUrl}/analyse/files/${encodeURIComponent(filename)}/`);
        await fetchFileList();
      } catch (error) {
        console.error('Fehler beim Löschen der Datei:', error);
        alert('Fehler beim Löschen der Datei. Bitte versuchen Sie es erneut.');
      }
    }
  };

  const handleCloseModal = () => {
    setShowFileBanner(false);
    if (fileContent) {
      URL.revokeObjectURL(fileContent);
      setFileContent('');
    }
  };

  const handleOpenFile = async (filename) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/get-file/${encodeURIComponent(filename)}`, {
        responseType: 'blob',
      });

      const fileURL = URL.createObjectURL(response.data);
      setFileContent(fileURL);
      setFileName(filename);
      setShowFileBanner(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Datei:', error);
      alert('Fehler beim Abrufen der Datei. Bitte versuchen Sie es erneut.');
    }
  };

  const handleDownloadFile = async (filename) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/get-file/${encodeURIComponent(filename)}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Fehler beim Herunterladen der Datei:', error);
      alert('Fehler beim Herunterladen der Datei. Bitte versuchen Sie es erneut.');
    }
  };

  const handleShowBemerkungen = (bemerkungenText) => {
    setSelectedBemerkungen(bemerkungenText);
    setShowBemerkungenModal(true);
  };

  const handleCloseBemerkungenModal = () => {
    setShowBemerkungenModal(false);
    setSelectedBemerkungen('');
  };

  return (
    <motion.div
      className='analyse-container'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Encabezado */}
      <header className="analyse-header">
        <h1 className="analyse-title">KI-Analyse</h1>
        <p className="analyse-subtitle">Analysieren Sie Ihre Daten mit unserer KI-gestützten Plattform</p>
      </header>

      {/* Sección de carga y análisis */}
      <section className="analyse-section">
        {/* Tarjeta de información */}
        <div className="analyse-card">
          <div className="card-content">
            <div className="card-icon">
              <i className='bx bx-cloud-upload' ></i>
            </div>
            <h2 className="card-title">Dateien hochladen</h2>
            <p className="card-description">
              Laden Sie Ihre Dateien hoch und lassen Sie unsere KI die benötigten Daten extrahieren.
            </p>

            {/* Formulario de carga */}
            <Form className="upload-form">
              <Form.Group controlId="fileInput" className="mb-3">
                <Form.Control type="file" multiple onChange={handleFileChange} ref={fileInputRef} disabled={analyzing} />
              </Form.Group>

              {/* Área de arrastrar y soltar */}
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'active' : ''}`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Dateien hier ablegen...</p>
                ) : (
                  <p>Ziehen Sie Dateien hierher, um sie hochzuladen</p>
                )}
              </div>

              {/* Botones de acción */}
              <div className="button-group">
                <Button onClick={handleFileUpload} className="custom-button" disabled={analyzing}>
                  <i className='bx bx-upload' ></i> Dateien hochladen
                </Button>
                <Button
                  onClick={handleAnalyzeAndUpdate}
                  className="custom-button"
                  disabled={analyzing}
                >
                  <i className='bx bx-brain' ></i> {analyzing ? 'Analyse läuft...' : 'Daten analysieren'}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {/* Alertas y mensajes */}
        {uploadMessage && <Alert variant="info" className="mt-3">{uploadMessage}</Alert>}
        {skippedFiles.length > 0 && (
          <Alert variant="warning" className="mt-3">
            Die folgenden Dateien wurden übersprungen, da sie bereits hochgeladen wurden und auf die Analyse warten: {skippedFiles.join(', ')}
          </Alert>
        )}
        {analyzing && (
          <div className="mt-3">
            <ProgressBar animated now={analysisProgress} label={`Analyse ${Math.round(analysisProgress)}%`} />
          </div>
        )}
      </section>

      {/* Sección de archivos pendientes */}
      <section className="pending-files-section">
        <h3>Dateien, die auf Analyse warten:</h3>
        <ListGroup variant="flush">
          {fileList.length > 0 ? (
            fileList.map((file, index) => (
              <ListGroup.Item key={index} className='file-item d-flex justify-content-between align-items-center'>
                {file}
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeletePendingFile(file)}
                  className="custom-btn-delete"
                >
                  <i className='bx bx-trash'></i>
                </Button>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>Keine Dateien warten auf Analyse.</ListGroup.Item>
          )}
        </ListGroup>
      </section>

      {/* Sección de resultados */}
      <section className="results-section">
        {/* Filtros */}
        <div className="filters-container">
          <div className="date-filter">
            <DatePicker
              selected={start_datum}
              onChange={(date) => setstart_datum(date)}
              dateFormat="dd.MM.yyyy"
              locale="de"
              className="form-control"
              placeholderText="Startdatum auswählen"
            />
          </div>
          <div className="date-filter">
            <DatePicker
              selected={end_datum}
              onChange={(date) => setend_datum(date)}
              dateFormat="dd.MM.yyyy"
              locale="de"
              className="form-control"
              placeholderText="Enddatum auswählen"
              minDate={start_datum}
            />
          </div>
          <div className="factor-filter">
            <Autocomplete
              multiple
              options={factors}
              getOptionLabel={(option) => option}
              value={selectedFactors}
              onChange={(event, newValue) => {
                setSelectedFactors(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter nach Faktoren"
                  placeholder="Faktoren auswählen"
                />
              )}
            />
          </div>
          {/* Botón de exportar */}
          <div className="export-button">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className='bx bx-download'></i> Daten exportieren
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <CSVLink data={csvData} filename={"analyse_ergebnisse.csv"} className="dropdown-item">
                  Als CSV exportieren
                </CSVLink>
                <Dropdown.Item onClick={exportTableAsImage}>Als Bild exportieren</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Tabla de resultados */}
        {filteredResults.length > 0 ? (
          <motion.div
            className="table-responsive"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Table hover responsive className="analysis-table" ref={tableRef}>
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Faktor</th>
                  <th>Wert</th>
                  <th>CO₂-Äquivalent</th>
                  <th className="bemerkungen-column">Bemerkungen</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {filteredResults.map((result, index) => (
                  <tr key={index} className="align-middle">
                    <td>
                      {result.date && result.date !== '' ? formatDate(result.date)
                        : (result.start_date && result.start_date !== '' && result.end_date && result.end_date !== ''
                          ? `${formatDate(result.start_date)} - ${formatDate(result.end_date)}`
                          : '')}
                    </td>
                    <td>{result.factor}</td>
                    <td>{result.value}</td>
                    <td>{result.co2_aequivalent.toFixed(4)}</td>
                    {/* Modificación de la columna 'Bemerkungen' */}
                    <td className="bemerkungen-column">
                      {result.bemerkungen && result.bemerkungen.trim() !== '' ? (
                        <Button
                          variant="outline-info"
                          size="sm"
                          onClick={() => handleShowBemerkungen(result.bemerkungen)}
                          className="custom-btn-action"
                        >
                          <i className='bx bx-info-circle'></i>
                        </Button>
                      ) : null}
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleOpenFile(result.filename)}
                        className="me-2 custom-btn-action"
                      >
                        <i className='bx bx-show'></i>
                      </Button>
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => handleDownloadFile(result.filename)}
                        className="me-2 custom-btn-action"
                      >
                        <i className='bx bx-download'></i>
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteResult(result.id)}
                        className="custom-btn-action"
                      >
                        <i className='bx bx-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </motion.div>
        ) : (
          <p>Keine Daten für den ausgewählten Datumsbereich verfügbar.</p>
        )}
      </section>

      {/* Modales y otros componentes adicionales */}
      {/* Modal para visualizar archivos */}
      <Modal show={showFileBanner} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '16px' }}>{fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={fileContent} width="100%" height="600px" title="PDF Viewer"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar Bemerkungen */}
      <Modal show={showBemerkungenModal} onHide={handleCloseBemerkungenModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Bemerkungen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedBemerkungen}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBemerkungenModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para confirmar reupload */}
      <Modal show={showConfirmationModal} onHide={declineReupload}>
        <Modal.Header closeButton>
          <Modal.Title>Datei(en) erneut hochladen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Die folgenden Dateien wurden bereits hochgeladen und analysiert:</p>
          <ul>
            {filesRequiringConfirmation.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
          <p>Möchten Sie diese Datei(en) erneut hochladen?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={declineReupload}>
            Nein
          </Button>
          <Button variant="primary" onClick={confirmReupload}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </motion.div>
  );
};

export default Analyse;