// FactorGraph.js
import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import '../styles/FactorGraph.css';
import ChartComponent from './ChartComponent';

const FactorGraph = ({ factor, factorLabel, unit, viewMode }) => {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);
  const [analysisResults, setAnalysisResults] = useState([]);

  // Función para obtener datos de la base de datos
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  useEffect(() => {
    // Filtrar los resultados para el factor específico
    const filteredResults = analysisResults.filter(
      (result) => result.factor === factor
    );

    if (filteredResults.length === 0) {
      setData(null);
      return;
    }

    // Procesar los resultados
    let processedResults = [];

    filteredResults.forEach((result) => {
      if (result.date) {
        // Si hay una fecha específica
        const resultDate = new Date(result.date);
        processedResults.push({
          date: formatDateLabel(resultDate, viewMode),
          value: Number(result.value),
          co2_equivalent: Number(result.co2_aequivalent),
        });
      } else if (result.start_date && result.end_date) {
        // Si hay un rango de fechas
        const start = new Date(result.start_date);
        const end = new Date(result.end_date);
        const totalDays = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
        if (totalDays <= 0) return;

        if (viewMode === 'daily') {
          // Distribuir el valor por día
          let currentDate = new Date(start);
          const dailyValue = Number(result.value) / totalDays;
          const dailyCO2 = Number(result.co2_aequivalent) / totalDays;

          while (currentDate <= end) {
            processedResults.push({
              date: currentDate.toISOString().split('T')[0],
              value: dailyValue,
              co2_equivalent: dailyCO2,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else if (viewMode === 'monthly') {
          // Distribuir el valor por mes
          let currentDate = new Date(start);
          const endMonth = end.getMonth() + end.getFullYear() * 12;
          const startMonth = start.getMonth() + start.getFullYear() * 12;
          const totalMonths = endMonth - startMonth + 1;
          const monthlyValue = Number(result.value) / totalMonths;
          const monthlyCO2 = Number(result.co2_aequivalent) / totalMonths;

          while (currentDate <= end) {
            const monthKey = formatDateLabel(currentDate, viewMode);
            processedResults.push({
              date: monthKey,
              value: monthlyValue,
              co2_equivalent: monthlyCO2,
            });
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (viewMode === 'yearly') {
          // Distribuir el valor por año
          let currentYear = start.getFullYear();
          const endYear = end.getFullYear();
          const totalYears = endYear - currentYear + 1;
          const yearlyValue = Number(result.value) / totalYears;
          const yearlyCO2 = Number(result.co2_aequivalent) / totalYears;

          while (currentYear <= endYear) {
            processedResults.push({
              date: currentYear.toString(),
              value: yearlyValue,
              co2_equivalent: yearlyCO2,
            });
            currentYear++;
          }
        }
      }
    });

    // Preparar datos para el gráfico
    let dateLabels = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      // Ordenar por fecha
      const sortedResults = processedResults.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      dateLabels = sortedResults.map((result) => result.date);
      values = sortedResults.map((result) => result.value);
      co2Equivalent = sortedResults.map((result) => result.co2_equivalent);
    } else if (viewMode === 'monthly') {
      const monthlyValues = {};
      const monthlyCO2 = {};

      processedResults.forEach((result) => {
        const monthKey = result.date;
        if (!monthlyValues[monthKey]) {
          monthlyValues[monthKey] = 0;
          monthlyCO2[monthKey] = 0;
        }
        monthlyValues[monthKey] += result.value;
        monthlyCO2[monthKey] += result.co2_equivalent;
      });

      const sortedMonths = Object.keys(monthlyValues).sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      });

      dateLabels = sortedMonths;
      values = sortedMonths.map((monthKey) => monthlyValues[monthKey]);
      co2Equivalent = sortedMonths.map((monthKey) => monthlyCO2[monthKey]);
    } else if (viewMode === 'yearly') {
      const yearlyValues = {};
      const yearlyCO2 = {};

      processedResults.forEach((result) => {
        const yearKey = result.date;
        if (!yearlyValues[yearKey]) {
          yearlyValues[yearKey] = 0;
          yearlyCO2[yearKey] = 0;
        }
        yearlyValues[yearKey] += result.value;
        yearlyCO2[yearKey] += result.co2_equivalent;
      });

      const sortedYears = Object.keys(yearlyValues).sort();

      dateLabels = sortedYears;
      values = sortedYears.map((yearKey) => yearlyValues[yearKey]);
      co2Equivalent = sortedYears.map((yearKey) => yearlyCO2[yearKey]);
    }

    if (dateLabels.length === 0) {
      setData(null);
      return;
    }

    setData({
      labels: dateLabels,
      datasets: [
        {
          label: `${factorLabel} (${unit})`,
          data: values,
          fill: true,
          backgroundColor: 'rgba(58,123,213,0.2)',
          borderColor: 'rgba(58,123,213,1)',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    });

    // Opciones del gráfico
    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Ocultamos la leyenda para un diseño minimalista
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleColor: '#ffffff',
          bodyColor: '#ffffff',
          titleFont: {
            family: 'Arial',
            size: 12,
            weight: 'bold',
          },
          bodyFont: {
            family: 'Arial',
            size: 12,
          },
        },
      },
      elements: {
        line: {
          tension: 0.4,
          borderWidth: 2,
        },
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          display: false, // Ocultamos el eje X para simplificar
        },
        y: {
          display: false, // Ocultamos el eje Y para simplificar
        },
      },
    });
  }, [analysisResults, viewMode, factor, factorLabel, unit]); // Asegúrate de incluir factorLabel y unit

  // Si no hay datos, no renderizamos el componente
  if (!data) {
    return null;
  }

  return (
    <div className="factor-graph">
      {/* Título minimalista */}
      <Typography variant="h6" className="factor-graph-title">
        {factorLabel}
      </Typography>

      <div className="chart-container">
        <ChartComponent
          data={data}
          options={options}
          chartRef={chartRef}
          chartType="line"
        />
      </div>
    </div>
  );
};

// Función auxiliar para formatear etiquetas de fecha
const formatDateLabel = (date, viewMode) => {
  if (viewMode === 'daily') {
    return date.toISOString().split('T')[0];
  } else if (viewMode === 'monthly') {
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleString('de-DE', options);
  } else if (viewMode === 'yearly') {
    return date.getFullYear().toString();
  }
};

export default FactorGraph;
