import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import TextField from '@mui/material/TextField';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { FaDownload } from 'react-icons/fa';

registerLocale('de', de);

const CustomInput = React.forwardRef(({ value, onClick, label }, ref) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onClick={onClick}
    ref={ref}
  />
));

const ControlsComponent = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  viewMode,
  setViewMode,
  chartType,
  setChartType,
  onDownload,
  downloadFormat,
  setDownloadFormat,
}) => {
  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleDownloadFormatChange = (event) => {
    setDownloadFormat(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
      {/* Rango de Fechas */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <ReactDatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<CustomInput label="Startdatum" />}
          locale="de"
          dateFormat="P"
        />
        <ReactDatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          minDate={startDate}
          customInput={<CustomInput label="Enddatum" />}
          locale="de"
          dateFormat="P"
        />
      </Box>

      {/* Selección de Ansicht y Diagrammtyp */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Ansicht</InputLabel>
          <Select
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
            label="Ansicht"
          >
            <MenuItem value="daily">Täglich</MenuItem>
            <MenuItem value="monthly">Monatlich</MenuItem>
            <MenuItem value="yearly">Jährlich</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Diagrammtyp</InputLabel>
          <Select
            value={chartType}
            onChange={handleChartTypeChange}
            label="Diagrammtyp"
          >
            <MenuItem value="line">Linie</MenuItem>
            <MenuItem value="bar">Balken</MenuItem>
            <MenuItem value="area">Fläche</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Descargar Gráfico */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Format</InputLabel>
          <Select
            value={downloadFormat}
            onChange={handleDownloadFormatChange}
            label="Format"
          >
            <MenuItem value="png">PNG</MenuItem>
            <MenuItem value="jpeg">JPEG</MenuItem>
            <MenuItem value="pdf">PDF</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          onClick={onDownload}
          fullWidth
          startIcon={<FaDownload />}
        >
          Grafik herunterladen
        </Button>
      </Box>
    </Box>
  );
};

export default ControlsComponent;