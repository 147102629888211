// Documentation.js
import React, { useEffect, useRef, useState } from 'react';
import '../styles/Documentation.css';

import { motion } from 'framer-motion';
import {
  FaBoxes,
  FaChargingStation,
  FaFireAlt,
  FaGasPump,
  FaHamburger,
  FaIndustry,
  FaPlane,
  FaRecycle,
  FaSeedling,
  FaServer,
  FaTemperatureHigh,
  FaTrash,
  FaTruckMoving,
  FaWater,
} from 'react-icons/fa';
import logo from '../assets/logo.png';
const factors = [
  // ... (Todos los factores del 1 al 16, incluyendo el último con el texto adicional)
  // Asegúrate de copiar todos los factores tal como se proporcionan a continuación
  {
    title: 'Benzin',
    icon: <FaGasPump size={30} color="#000000" />,
    description:
      'Benzin ist ein Kraftstoff, der hauptsächlich in Fahrzeugen und Maschinen verwendet wird. Seine Verbrennung in Verbrennungsmotoren erzeugt CO₂-Emissionen.',
    factor: '1 Liter Benzin entspricht 0,0023 Tonnen CO₂.',
    reason:
      'Dieser Faktor basiert auf der durchschnittlichen Menge an CO₂, die bei der Verbrennung eines Liters Benzin freigesetzt wird. Das Umweltbundesamt stellt diesen Wert bereit, der als Standard für die Berechnung von Fahrzeugeemissionen gilt.',
    dataSuggestion:
      'Für eine genaue Berechnung sollten die insgesamt verbrauchten Liter Benzin angegeben werden. Diese Daten können aus den Tankrechnungen der Firmenfahrzeugflotte entnommen werden. LIKS kann diese Aufzeichnungen analysieren, um die gesamten Emissionen zu berechnen, die mit dem Benzinverbrauch verbunden sind.',
    sourceLink: 'https://www.umweltbundesamt.de/',
  },
  {
    title: 'Diesel',
    icon: <FaGasPump size={30} color="#000000" />,
    description:
      'Diesel ist ein weiterer häufig verwendeter Kraftstoff in Fahrzeugen und schweren Maschinen. Die Verbrennung von Diesel setzt ebenfalls CO₂ in die Atmosphäre frei.',
    factor: '1 Liter Diesel entspricht 0,0027 Tonnen CO₂.',
    reason:
      'Dieser Faktor reflektiert die CO₂-Emissionen, die bei der Verbrennung eines Liters Diesel aufgrund seines Kohlenstoffgehalts entstehen. Quelle: Umweltbundesamt',
    dataSuggestion:
      'Geben Sie den gesamten Dieselverbrauch in Litern an, der aus den Kraftstoffrechnungen entnommen werden kann. LIKS kann diese Daten nutzen, um die Emissionen zu berechnen, die durch die Fahrzeugflotte oder dieselbetriebene Geräte verursacht werden.',
    sourceLink: 'https://www.umweltbundesamt.de/',
  },
  {
    title: 'Stromverbrauch',
    icon: <FaChargingStation size={30} color="#000000" />,
    description:
      'Der Stromverbrauch im Unternehmen resultiert aus verschiedenen Anwendungen wie Beleuchtung, Heizung und dem Betrieb von Maschinen. Die Menge des ausgestoßenen CO₂ hängt von der Quelle der Stromerzeugung ab.',
    factor: '1 kWh Stromverbrauch entspricht 0,000475 Tonnen CO₂.',
    reason:
      'Dieser Faktor berücksichtigt den durchschnittlichen Energiemix, der für die Stromerzeugung verwendet wird, einschließlich fossiler und erneuerbarer Quellen.',
    dataSuggestion:
      'Nützliche Daten sind der gesamte Stromverbrauch in Kilowattstunden (kWh), der auf den Stromrechnungen des Unternehmens zu finden ist. LIKS kann diese Informationen verarbeiten, um die CO₂-Emissionen entsprechend dem jeweiligen Energiemix zu berechnen.',
    sourceLink: 'https://www.iea.org/',
  },
  {
    title: 'Erdgas',
    icon: <FaFireAlt size={30} color="#000000" />,
    description:
      'Erdgas wird im Unternehmen für Heizung und industrielle Prozesse eingesetzt. Seine Verbrennung erzeugt CO₂-Emissionen.',
    factor: '1 m³ Erdgas entspricht 0,002 Tonnen CO₂.',
    reason:
      'Dieser Faktor basiert auf dem Kohlenstoffgehalt des Erdgases und den resultierenden Emissionen seiner Verbrennung.',
    dataSuggestion:
      'Geben Sie den gesamten Erdgasverbrauch in Kubikmetern (m³) oder Kilowattstunden (kWh) gemäß den Gasrechnungen an. LIKS kann diese Daten verwenden, um die Emissionen zu berechnen, die aus der Nutzung von Erdgas im Unternehmen resultieren.',
    sourceLink: 'https://www.iea.org/',
  },
  {
    title: 'Flüssiggas (LPG)',
    icon: <FaGasPump size={30} color="#000000" />,
    description:
      'Flüssiggas wird für Heizung, Kochen und in einigen Fahrzeugen verwendet. Bei seiner Verbrennung entstehen CO₂-Emissionen.',
    factor: '1 Liter LPG entspricht 0,0016 Tonnen CO₂.',
    reason:
      'Der Faktor basiert auf den CO₂-Emissionen, die bei der Verbrennung eines Liters Flüssiggas entstehen, unter Berücksichtigung seiner Zusammensetzung und seines Kohlenstoffgehalts.',
    dataSuggestion:
      'Geben Sie die insgesamt verbrauchten Liter LPG an, die aus den Lieferrechnungen entnommen werden können. LIKS kann diese Informationen verwenden, um die CO₂-Emissionen zu berechnen, die mit dem LPG-Verbrauch verbunden sind.',
    sourceLink: 'https://www.iea.org/',
  },
  {
    title: 'Wasser',
    icon: <FaWater size={30} color="#000000" />,
    description:
      'Der Wasserverbrauch hat Auswirkungen auf die CO₂-Emissionen aufgrund der Prozesse der Gewinnung, Aufbereitung und Verteilung.',
    factor: '1 m³ Wasser entspricht 0,000194 Tonnen CO₂.',
    reason:
      'Dieser Faktor berücksichtigt die Emissionen, die bei den notwendigen Vorgängen entstehen, um sauberes Wasser bereitzustellen, von der Gewinnung bis zur Lieferung.',
    dataSuggestion:
      'Geben Sie den gesamten Wasserverbrauch in Kubikmetern (m³) aus den Wasserrechnungen an. LIKS kann diese Daten verarbeiten, um die mit dem Wasserverbrauch im Unternehmen verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://waterfootprint.org/',
  },
  {
    title: 'Erzeugte Abfälle',
    icon: <FaTrash size={30} color="#000000" />,
    description:
      'Die Erzeugung und Behandlung von Abfällen trägt zu CO₂-Emissionen bei, insbesondere wenn sie verbrannt oder auf Deponien abgebaut werden.',
    factor: '1 Tonne Abfall entspricht 0,1 Tonnen CO₂.',
    reason:
      'Der Faktor umfasst die Emissionen, die während der Abfallbewirtschaftung entstehen, sei es durch Verbrennung oder anaeroben Abbau.',
    dataSuggestion:
      'Für eine präzise Bewertung geben Sie die insgesamt erzeugte Abfallmenge in Tonnen an. Diese Daten können in den Abfallwirtschaftsberichten des Unternehmens gefunden werden. LIKS kann diese Informationen verwenden, um die mit der Abfallentsorgung verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://www.epa.gov/',
  },
  {
    title: 'Geschäftsreisen (Flugreisen)',
    icon: <FaPlane size={30} color="#000000" />,
    description:
      'Flugreisen erzeugen eine beträchtliche Menge an CO₂-Emissionen, insbesondere bei Langstreckenflügen.',
    factor: '1 km Flug pro Passagier entspricht 0,000115 Tonnen CO₂.',
    reason:
      'Dieser Faktor berücksichtigt den durchschnittlichen CO₂-Ausstoß pro Kilometer Flug in kommerziellen Flugzeugen.',
    dataSuggestion:
      'Geben Sie die zurückgelegte Entfernung bei Geschäftsflügen in Kilometern und die Anzahl der Passagiere an. Reiseaufzeichnungen und Flugticketrechnungen enthalten diese Informationen. LIKS kann diese Daten verwenden, um die gesamten Emissionen aus Flugreisen zu berechnen.',
    sourceLink: 'https://www.iata.org/',
  },
  {
    title: 'Papierverbrauch',
    icon: <FaRecycle size={30} color="#000000" />,
    description:
      'Die Produktion und Nutzung von Papier erzeugt CO₂-Emissionen, insbesondere wenn es nicht recycelt wird.',
    factor: '1 kg Frischfaserpapier entspricht 0,0033 Tonnen CO₂.',
    reason:
      'Dieser Faktor berücksichtigt die Emissionen, die während der Papierherstellung entstehen, von der Baumfällung bis zur Verarbeitung.',
    dataSuggestion:
      'Geben Sie die insgesamt verbrauchte Papiermenge in Kilogramm an. Diese Informationen können aus den Büromaterialrechnungen entnommen werden. LIKS kann diese Daten analysieren, um die mit dem Papierverbrauch verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://environmentalpaper.org/',
  },
  {
    title: 'Technologienutzung (Server)',
    icon: <FaServer size={30} color="#000000" />,
    description:
      'Rechenzentren und Server verbrauchen erhebliche Mengen an Elektrizität und tragen zu CO₂-Emissionen bei.',
    factor: '1 Stunde Serverbetrieb entspricht 0,0005 Tonnen CO₂.',
    reason:
      'Der Faktor spiegelt den durchschnittlichen Energieverbrauch eines Servers und die resultierenden Emissionen seines Betriebs wider.',
    dataSuggestion:
      'Geben Sie die gesamten Serverbetriebsstunden an. IT-Aufzeichnungen enthalten in der Regel diese Informationen. LIKS kann diese Daten verwenden, um die mit dem Serverbetrieb verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://www.greenpeace.org/',
  },
  {
    title: 'Kunststoffproduktion',
    icon: <FaBoxes size={30} color="#000000" />,
    description:
      'Die Herstellung und Nutzung von Kunststoffen trägt zu CO₂-Emissionen bei, von der Rohstoffgewinnung bis zur Verarbeitung.',
    factor: '1 kg Kunststoff entspricht 0,0025 Tonnen CO₂.',
    reason:
      'Dieser Faktor berücksichtigt die Emissionen während des gesamten Lebenszyklus von Kunststoffen, einschließlich Produktion und Endentsorgung.',
    dataSuggestion:
      'Geben Sie die insgesamt verwendete oder produzierte Kunststoffmenge in Kilogramm an. Produktionsaufzeichnungen oder Einkaufsrechnungen enthalten diese Informationen. LIKS kann diese Daten verwenden, um die Emissionen abzuschätzen, die mit der Kunststoffnutzung verbunden sind.',
    sourceLink: 'https://www.plasticseurope.org/',
  },
  {
    title: 'Kältemittel (Nutzung von Kühlgeräten)',
    icon: <FaTemperatureHigh size={30} color="#000000" />,
    description:
      'Die in Kühl- und Klimaanlagen verwendeten Kältemittel haben ein hohes Treibhauspotenzial.',
    factor:
      'R134a: 1 kg = 1,43 Tonnen CO₂.\nR410A: 1 kg = 2,088 Tonnen CO₂.',
    reason:
      'Diese Faktoren spiegeln das Treibhauspotenzial (GWP) der Kältemittel und ihren Einfluss auf die CO₂-Emissionen wider.',
    dataSuggestion:
      'Geben Sie die Menge der nachgefüllten oder verwendeten Kältemittel in Kilogramm an. Diese Informationen finden sich in den Wartungsunterlagen der Kälteanlagen. LIKS kann diese Daten analysieren, um die mit der Nutzung von Kältemitteln verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://www.ipcc.ch/report/ar5/',
  },
  {
    title: 'Transport von Produkten',
    icon: <FaTruckMoving size={30} color="#000000" />,
    description:
      'Der Transport von Produkten, sei es auf der Straße, Schiene oder See, erzeugt CO₂-Emissionen durch den Kraftstoffverbrauch.',
    factor: '1 Tonnenkilometer (tkm) Transport entspricht 0,00025 Tonnen CO₂.',
    reason:
      'Dieser Faktor reflektiert die durchschnittlichen Emissionen, die beim Transport von Gütern pro Tonne Ladung und zurückgelegtem Kilometer entstehen.',
    dataSuggestion:
      'Geben Sie die insgesamt zurückgelegte Entfernung und das Gewicht der transportierten Produkte an. Diese Daten können aus den Logistik- und Transportaufzeichnungen entnommen werden. LIKS kann diese Informationen verwenden, um die Emissionen zu berechnen, die mit dem Produkttransport verbunden sind.',
    sourceLink: 'https://www.iea.org/',
  },
  {
    title: 'Verbrauch von landwirtschaftlichen Produkten',
    icon: <FaHamburger size={30} color="#000000" />,
    description:
      'Die Produktion und der Transport landwirtschaftlicher Produkte erzeugen CO₂-Emissionen aufgrund des Energieverbrauchs und der beteiligten Prozesse.',
    factor:
      'Rindfleisch: 1 kg = 0,027 Tonnen CO₂e.\nHuhn: 1 kg = 0,0069 Tonnen CO₂e.\nGemüse: 1 kg = 0,002 Tonnen CO₂e.',
    reason:
      'Diese Faktoren berücksichtigen die Emissionen, die während des gesamten Lebenszyklus landwirtschaftlicher Produkte entstehen, von der Produktion bis zum Transport.',
    dataSuggestion:
      'Geben Sie die Menge der verbrauchten landwirtschaftlichen Produkte in Kilogramm an und spezifizieren Sie die Produktarten. Diese Informationen finden sich auf den Einkaufsrechnungen. LIKS kann diese Daten nutzen, um die mit dem Lebensmittelverbrauch verbundenen Emissionen zu berechnen.',
    sourceLink: 'https://ourworldindata.org/',
  },
  {
    title: 'Produktionsemissionen (Fabriken und Anlagen)',
    icon: <FaIndustry size={30} color="#000000" />,
    description:
      'Industrielle Produktionsprozesse erzeugen CO₂-Emissionen, insbesondere bei der Herstellung von Waren und Produkten.',
    factor: '1 Tonne hergestelltes Produkt entspricht 0,5 Tonnen CO₂.',
    reason:
      'Dieser Faktor basiert auf den durchschnittlichen CO₂-Emissionen, die während industrieller Produktionsprozesse entstehen.',
    dataSuggestion:
      'Geben Sie das gesamte Produktionsvolumen in Tonnen an. Produktionsberichte und interne Aufzeichnungen enthalten diese Informationen. LIKS kann diese Daten verwenden, um die aus der Produktion resultierenden Emissionen zu berechnen.',
    sourceLink: 'https://www.ipcc.ch/',
  },
  {
    title: 'Nutzung erneuerbarer Energien',
    icon: <FaSeedling size={30} color="#000000" />,
    description:
      'Die Nutzung erneuerbarer Energien wie Solar-, Wind- oder Wasserkraft reduziert die CO₂-Emissionen des Unternehmens.',
    factor: '1 kWh erneuerbare Energie entspricht 0 Tonnen CO₂.',
    reason:
      'Die Erzeugung von Strom aus erneuerbaren Quellen emittiert während des Betriebs kein CO₂.',
    dataSuggestion:
      'Geben Sie den gesamten Verbrauch erneuerbarer Energien in kWh an, sofern er auf den Stromrechnungen angegeben ist. LIKS kann diese Informationen verwenden, um die Reduzierung der CO₂-Emissionen des Unternehmens zu berechnen.',
    sourceLink: 'https://www.ren21.net/reports/global-status-report/',
    
  },
  {
    logo: logo, // Agregamos el logo aquí
    icon: null, // Deja el icono como null ya que usaremos el logo en su lugar
    additionalInfo:
      'Die Umrechnungsfaktoren werden ständig aktualisiert, um den neuesten Vorschriften oder Standards im Zusammenhang mit ESG zu entsprechen.',
  
  
  },
];

const Documentation = () => {
  const [activeTab, setActiveTab] = useState('umrechnungsfaktoren');

  return (
    <div className="documentation-container">
      {/* Navegación */}
      <div className="navigation">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'umrechnungsfaktoren' ? 'active' : ''}
          onClick={() => setActiveTab('umrechnungsfaktoren')}
        >
          Umrechnungsfaktoren
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'handbuch' ? 'active' : ''}
          onClick={() => setActiveTab('handbuch')}
        >
          Handbuch
        </motion.button>
        {/* <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={activeTab === 'about' ? 'active' : ''}
          onClick={() => setActiveTab('about')}
        >
          About
        </motion.button> */}
      </div>
      {/* Contenido */}
      <div className="content">
        {activeTab === 'umrechnungsfaktoren' && (
          <CardScroller factors={factors} />
        )}
        {activeTab === 'handbuch' && (
          <div className="pdf-container">
            <iframe
              src="/handbuch.pdf"
              title="Handbuch"
              width="100%"
              height="100%"
              style={{ border: 'none', minHeight: '80vh' }}
            ></iframe>
          </div>
        )}
        {activeTab === 'about' && (
          <div className="about-content">
            <p>LIKS GmbH</p>
            <p>Brüsseler Straße 5, 65552 Limburg</p>
            <p>E-Mail: Support@liks.gmbh</p>
            
          </div>
        )}
      </div>
    </div>
  );
};

const CardScroller = ({ factors }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = () => {
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const cardHeight = container.clientHeight;
    const index = Math.round(scrollTop / cardHeight);
    setCurrentIndex(index);
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="card-scroller" ref={containerRef}>
      <div className="cards-wrapper">
        {factors.map((factor, index) => (
          <div key={index} className="card big-card">
            {/* Mostrar el logo en la última tarjeta */}
            {factor.logo ? (
              <img
                src={factor.logo}
                alt="LIKS Logo"
                className="logo-image"
              />
            ) : (
              <div className="icon-container">{factor.icon}</div>
            )}
            <h3>{factor.title}</h3>
            <div className="card-content">
              {factor.description && (
                <>
                  <h4>Beschreibung</h4>
                  <p>{factor.description}</p>
                </>
              )}
              {factor.factor && (
                <>
                  <h4>Umrechnungsfaktor</h4>
                  <p>{factor.factor}</p>
                </>
              )}
              {factor.reason && (
                <>
                  <h4>Begründung und Quelle</h4>
                  <p>
                    {factor.reason}{' '}
                    {factor.sourceLink && (
                      <a
                        href={factor.sourceLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        [Link zur Quelle]
                      </a>
                    )}
                  </p>
                </>
              )}
              {factor.dataSuggestion && (
                <>
                  <h4>Datenvorschlag für LIKS</h4>
                  <p>{factor.dataSuggestion}</p>
                </>
              )}
              {factor.additionalInfo && (
                <>
                  <p>{factor.additionalInfo}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-dots">
        {factors.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Documentation;