import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ChartComponent from '../components/ChartComponent';
import ControlsComponent from '../components/ControlsComponent';

const Kunststoffproduktion = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewMode, setViewMode] = useState('daily');
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [chartType, setChartType] = useState('line');
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  // Estado para almacenar los resultados de análisis
  const [analysisResults, setAnalysisResults] = useState([]);

  // Función para obtener los datos desde la BD
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Obtener los datos al montar el componente
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Procesar los datos cada vez que cambien los filtros o los resultados
  useEffect(() => {
    const filteredAnalysisResults = analysisResults.filter(result => result.factor === 'kunststoffproduktion');

    const sortedResults = [...filteredAnalysisResults].sort((a, b) => new Date(a.date) - new Date(b.date));

    const filteredResults = sortedResults.filter((result) => {
      const resultDate = new Date(result.date);
      const isAfterStart = startDate ? resultDate >= startDate : true;
      const isBeforeEnd = endDate ? resultDate <= endDate : true;
      return isAfterStart && isBeforeEnd;
    });

    let dates = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      dates = filteredResults.map(result => result.date);
      values = filteredResults.map(result => Number(result.value)); // Extraer el valor desde "value"
      co2Equivalent = filteredResults.map(result => Number(result.co2_aequivalent));
    } else if (viewMode === 'monthly') {
      const monthlyDataValues = {};
      const monthlyDataCO2 = {};

      filteredResults.forEach(result => {
        const date = new Date(result.date);
        const month = date.toLocaleString('de-DE', { month: 'short', year: 'numeric' });
        if (!monthlyDataValues[month]) {
          monthlyDataValues[month] = 0;
          monthlyDataCO2[month] = 0;
        }
        monthlyDataValues[month] += Number(result.value); // Sumar valores de "value"
        monthlyDataCO2[month] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(monthlyDataValues);
      values = Object.values(monthlyDataValues);
      co2Equivalent = Object.values(monthlyDataCO2);
    } else if (viewMode === 'yearly') {
      const yearlyDataValues = {};
      const yearlyDataCO2 = {};

      filteredResults.forEach(result => {
        const date = new Date(result.date);
        const year = date.getFullYear();
        if (!yearlyDataValues[year]) {
          yearlyDataValues[year] = 0;
          yearlyDataCO2[year] = 0;
        }
        yearlyDataValues[year] += Number(result.value); // Sumar valores de "value"
        yearlyDataCO2[year] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(yearlyDataValues);
      values = Object.values(yearlyDataValues);
      co2Equivalent = Object.values(yearlyDataCO2);
    }

    setData({
      labels: dates,
      datasets: [
        {
          label: 'Kunststoffproduktion (kg)',
          data: values,
          fill: true,
          backgroundColor: 'rgba(255,206,86,0.2)',
          borderColor: 'rgba(255,206,86,1)',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(54,162,235,0.2)',
          borderColor: 'rgba(54,162,235,1)',
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    });

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'kg',
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    });
  }, [analysisResults, startDate, endDate, viewMode]);

  // Función para descargar el gráfico en el formato seleccionado
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image(downloadFormat);
    const link = document.createElement('a');
    link.href = url;
    link.download = `kunststoffproduktion_co2.${downloadFormat}`;
    link.click();
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Gráfico con mayor altura y separación */}
      {data.labels && data.labels.length > 0 ? (
        <div className="chart-container" style={{ height: '80vh', marginBottom: '40px' }}>
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <p>Für den gewählten Datumsbereich sind keine Daten verfügbar.</p>
      )}

      {/* Controles con margen inferior */}
      <div style={{ marginBottom: '20px' }}>
        <ControlsComponent
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>
    </div>
  );
};

export default Kunststoffproduktion;