// src/pages/GesamtEmissionen.js

import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import ChartComponent from '../components/ChartComponent';
import ControlsComponent from '../components/ControlsComponent';
import '../styles/GesamtEmissionen.css';

// Importieren von Icons
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';

const GesamtEmissionen = () => {
  // Konfiguration
  const defaultStartDate = new Date('2022-01-01');
  const defaultEndDate = new Date('2022-12-31');

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [viewMode, setViewMode] = useState('monthly');
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [chartType, setChartType] = useState('bar');
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  const [analysisResults, setAnalysisResults] = useState([]);
  const [shareLink, setShareLink] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Zustände für zusätzliche Visualisierungen
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [topFactors, setTopFactors] = useState([]);

  // Funktion zum Abrufen der Analyseergebnisse
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;

      // Filtern der gültigen Ergebnisse
      const validResults = results.filter(result => {
        const date = new Date(result.date);
        return result.co2_aequivalent && result.date && !isNaN(date.getTime());
      });

      setAnalysisResults(validResults);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  useEffect(() => {
    const filteredResults = analysisResults.filter(result => {
      return result.co2_aequivalent && result.date && !isNaN(new Date(result.date).getTime());
    });

    // Filtern nach Datum
    const finalResults = filteredResults.filter(result => {
      const resultDate = new Date(result.date);
      const isAfterStart = startDate ? resultDate >= startDate : true;
      const isBeforeEnd = endDate ? resultDate <= endDate : true;
      return isAfterStart && isBeforeEnd;
    });

    // Berechnen der gesamten Emissionen
    const total = finalResults.reduce((sum, result) => sum + Number(result.co2_aequivalent), 0);
    setTotalEmissions(total);

    // Daten für das Balkendiagramm
    let dates = [];
    let co2Sums = [];

    if (viewMode === 'daily') {
      dates = finalResults.map(result => result.date);
      co2Sums = finalResults.map(result => Number(result.co2_aequivalent));
    } else if (viewMode === 'monthly') {
      const monthlyData = {};

      finalResults.forEach(result => {
        const date = new Date(result.date);
        const month = date.toLocaleString('de-DE', { month: 'short', year: 'numeric' });
        if (!monthlyData[month]) {
          monthlyData[month] = 0;
        }
        monthlyData[month] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(monthlyData);
      co2Sums = Object.values(monthlyData);
    } else if (viewMode === 'yearly') {
      const yearlyData = {};

      finalResults.forEach(result => {
        const date = new Date(result.date);
        const year = date.getFullYear();
        if (!yearlyData[year]) {
          yearlyData[year] = 0;
        }
        yearlyData[year] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(yearlyData);
      co2Sums = Object.values(yearlyData);
    }

    // Berechnen der Top-Faktoren
    const factorData = {};
    finalResults.forEach(result => {
      const factor = result.factor || 'Unbekannt';
      if (!factorData[factor]) {
        factorData[factor] = 0;
      }
      factorData[factor] += Number(result.co2_aequivalent);
    });

    const sortedFactors = Object.entries(factorData)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5); // Top 5 Faktoren

    setTopFactors(sortedFactors);

    if (dates.length === 0) {
      setData({});
      setOptions({});
      return;
    }

    setData({
      labels: dates,
      datasets: [
        {
          label: 'Gesamte CO₂-Emissionen (Tonnen)',
          data: co2Sums,
          backgroundColor: '#2196f3',
          borderColor: '#1e88e5',
          borderWidth: 2,
          borderRadius: 5,
        },
      ],
    });

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#ffffff',
          titleColor: '#000',
          bodyColor: '#000',
          borderColor: '#ccc',
          borderWidth: 1,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 14,
              family: 'Roboto, Arial, sans-serif',
            },
            color: '#000',
            maxRotation: 45,
            minRotation: 45,
            autoSkip: true,
            maxTicksLimit: 10,
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'Tonnen',
            font: {
              size: 16,
              family: 'Roboto, Arial, sans-serif',
              weight: 'bold',
            },
            color: '#000',
          },
          ticks: {
            font: {
              size: 14,
              family: 'Roboto, Arial, sans-serif',
            },
            color: '#000',
            callback: function(value) {
              return `${value}t`;
            },
          },
        },
      },
    });
  }, [analysisResults, startDate, endDate, viewMode]);

  // Funktionen zum Herunterladen und Teilen des Diagramms
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image();
    const link = document.createElement('a');
    link.href = url;
    link.download = `GesamtEmissionen_co2.${downloadFormat}`;
    link.click();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShare = async () => {
    setIsLoading(true);

    try {
      // Erstellung der Diagrammkonfiguration für QuickChart
      const chartConfig = {
        type: chartType,
        data: data,
        options: options,
      };

      // Serialisieren der Diagrammkonfiguration
      const configString = JSON.stringify(chartConfig);

      // Kodieren der Konfiguration, um sie in einer URL einzuschließen
      const encodedConfig = encodeURIComponent(configString);

      // Generieren der QuickChart-URL mit vollständigen Abmessungen
      const quickChartUrl = `https://quickchart.io/chart?c=${encodedConfig}&width=1920&height=1080&format=png&backgroundColor=white`;

      // Verkürzen der URL mit TinyURL
      const tinyUrlResponse = await axios.get(`https://tinyurl.com/api-create.php?url=${encodeURIComponent(quickChartUrl)}`);

      const shortUrl = tinyUrlResponse.data;

      // Kopieren der verkürzten URL in die Zwischenablage
      await navigator.clipboard.writeText(shortUrl);

      // Speichern der verkürzten URL im Zustand, um sie im Dialog anzuzeigen
      setShareLink(shortUrl);

      // Öffnen des Dialogs nach dem Kopieren des Links
      handleOpen();
    } catch (error) {
      console.error('Fehler beim Teilen des Links:', error);
      alert('Es ist ein Fehler aufgetreten beim Versuch, das Diagramm zu teilen. Bitte versuchen Sie es erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className='gesamt-emissionen-container'>
 
 {/* Hauptdiagramm */}
 {data.labels && data.labels.length > 0 ? (
        <div className="chart-container">
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <Typography variant="h6" align="center">Für den ausgewählten Datumsbereich sind keine Daten verfügbar.</Typography>
      )}

      {/* Übersichtskarten */}
      <Grid container spacing={3} className="summary-cards">
        <Grid item xs={12} md={6}>
          <Card className="summary-card equal-height">
            <CardContent className="card-content">
              <div className="card-icon">
                <TrendingUpIcon style={{ fontSize: 40 }} />
              </div>
              <Typography variant="h6" className="card-title">
                Gesamte Emissionen
              </Typography>
              <Typography variant="h4" className="card-value">
                {totalEmissions.toFixed(2)} t
              </Typography>
              <Typography variant="body2" className="card-subtitle">
                Für den ausgewählten Zeitraum
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className="summary-card equal-height">
            <CardContent className="card-content">
              <div className="card-icon">
                <WhatshotIcon style={{ fontSize: 40 }} />
              </div>
              <Typography variant="h6" className="card-title">
                Hauptfaktoren
              </Typography>
              <div className="factors-list">
                {topFactors.length > 0 ? (
                  <Grid container spacing={2}>
                    {topFactors.map(([factor, value]) => (
                      <Grid item xs={6} key={factor}>
                        <div className="factor-item">
                          <Typography variant="subtitle1" className="factor-name">
                            {factor}
                          </Typography>
                          <Typography variant="h6" className="factor-value">
                            {value.toFixed(2)} t
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="body2">Keine Daten zum Anzeigen.</Typography>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Steuerungen */}
      <div className="controls-container">
        <ControlsComponent
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>

     
      {/* Teilen-Button */}
      <div className="share-button-container">
        <Button
          variant="contained"
          color="primary"
          onClick={handleShare}
          className="custom-btn"
          startIcon={<ShareIcon />}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Diagramm teilen'}
        </Button>
      </div>

      {/* Teilen-Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>Diagramm teilen</DialogTitle>
        <DialogContent className="share-dialog">
          <Typography variant="body1">Kopieren Sie den folgenden Link oder scannen Sie den QR-Code:</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={shareLink}
            className="share-link"
            InputProps={{
              readOnly: true,
            }}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          />
          <div className="qr-code">
            <QRCodeSVG
              value={shareLink}
              size={300}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="H"
              includeMargin={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GesamtEmissionen;