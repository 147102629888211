import 'boxicons/css/boxicons.min.css';
import React from 'react';
import '../styles/sidebar.css';

function Sidebar({ activePage, onSelectPage, isOpen, closeSidebar }) {
  const handleNavClick = (page) => {
    onSelectPage(page);
    if (window.innerWidth < 576) {
      closeSidebar();
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <nav className="sidebar-nav">
        <ul className="nav-list">
          {/* Start */}
          <li
            className={`nav-item ${activePage === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleNavClick('dashboard')}
          >
            <i className='bx bx-home icon'></i>
            <span className="link-text">Start</span>
          </li>

          {/* Übersicht */}
          <li
            className={`nav-item ${activePage === 'ubersicht' ? 'active' : ''}`}
            onClick={() => handleNavClick('ubersicht')}
          >
            <i className='bx bx-grid-alt icon'></i>
            <span className="link-text">Übersicht</span>
          </li>

          {/* Sección: Umwelt */}
          <div className="section-title">Umwelt</div>

          {/* KI-Analyse */}
          <li
            className={`nav-item ${activePage === 'analyse' ? 'active' : ''}`}
            onClick={() => handleNavClick('analyse')}
          >
            <i className='bx bx-brain icon'></i>
            <span className="link-text">KI-Analyse</span>
          </li>

          {/* Gesamte Emissionen */}
          <li
            className={`nav-item ${activePage === 'gesamt-emissionen' ? 'active' : ''}`}
            onClick={() => handleNavClick('gesamt-emissionen')}
          >
            <i className='bx bx-bar-chart-alt-2 icon'></i>
            <span className="link-text">Gesamte Emissionen</span>
          </li>

          {/* Scope 1 */}
          <li
            className={`nav-item ${activePage === 'scope1' ? 'active' : ''}`}
            onClick={() => handleNavClick('scope1')}
          >
            <i className='bx bxs-factory icon'></i>
            <span className="link-text">Scope 1</span>
          </li>

          {/* Scope 2 */}
          <li
            className={`nav-item ${activePage === 'scope2' ? 'active' : ''}`}
            onClick={() => handleNavClick('scope2')}
          >
            <i className='bx bx-bolt-circle icon'></i>
            <span className="link-text">Scope 2</span>
          </li>

          {/* Scope 3 */}
          <li
            className={`nav-item ${activePage === 'scope3' ? 'active' : ''}`}
            onClick={() => handleNavClick('scope3')}
          >
            <i className='bx bx-world icon'></i>
            <span className="link-text">Scope 3</span>
          </li>

          {/* Sección: Soziale & Governance */}
          <div className="section-title">Soziale & Governance</div>

          {/* Soziale */}
          <li
            className={`nav-item ${activePage === 'social' ? 'active' : ''}`}
            onClick={() => handleNavClick('social')}
          >
            <i className='bx bx-group icon'></i>
            <span className="link-text">Soziale</span>
          </li>

          {/* Governance */}
          <li
            className={`nav-item ${activePage === 'governance' ? 'active' : ''}`}
            onClick={() => handleNavClick('governance')}
          >
            <i className='bx bx-shield-quarter icon'></i>
            <span className="link-text">Governance</span>
          </li>

          {/* Sección: Weitere Informationen */}
          <div className="section-title">Weitere Informationen</div>

          {/* Einstellungen */}
          <li
            className={`nav-item ${activePage === 'settings' ? 'active' : ''}`}
            onClick={() => handleNavClick('settings')}
          >
            <i className='bx bx-cog icon'></i>
            <span className="link-text">Einstellungen</span>
          </li>

          {/* Impressum */}
          <li
            className={`nav-item ${activePage === 'impressum' ? 'active' : ''}`}
            onClick={() => handleNavClick('impressum')}
          >
            <i className='bx bx-info-circle icon'></i>
            <span className="link-text">Impressum</span>
          </li>
         {/* Impressum */}
         <li
            className={`nav-item ${activePage === 'conversionfactors' ? 'active' : ''}`}
            onClick={() => handleNavClick('conversionfactors')}
          >
            <i className='bx bx-calculator  icon'></i>
            <span className="link-text">umrechnungsfaktoren </span>
          </li>
           
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;