// ./src/pages/Stromverbrauch.js
import ShareIcon from '@mui/icons-material/Share';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { QRCodeSVG } from 'qrcode.react'; // Para mostrar el código QR
import React, { useEffect, useRef, useState } from 'react';
import ChartComponent from '../components/ChartComponent';
import ControlsComponent from '../components/ControlsComponent';

// Registrar los plugins de Chart.js
Chart.register(...registerables, ChartDataLabels);

const Stromverbrauch = () => {
  // Configurar el rango de fechas por defecto a 2022
  const defaultStartDate = new Date('2022-01-01');
  const defaultEndDate = new Date('2022-12-31');

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [viewMode, setViewMode] = useState('monthly'); // Puede ser 'daily', 'monthly', 'yearly'
  const [data, setData] = useState({}); // Inicializar como objeto vacío
  const [options, setOptions] = useState({}); // Inicializar como objeto vacío
  const [chartType, setChartType] = useState('bar'); // Usar 'bar' para compatibilidad
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  // Estado para almacenar los resultados de análisis
  const [analysisResults, setAnalysisResults] = useState([]);

  // Estados para la funcionalidad de compartir
  const [shareLink, setShareLink] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Función para obtener los datos desde la BD
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Obtener los datos al montar el componente
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Función auxiliar para formatear las fechas (solo meses y años en alemán)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatMonthYear = (date) => {
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleString('de-DE', options);
  };

  // Procesar los datos cada vez que cambien los filtros o los resultados
  useEffect(() => {
    const filteredAnalysisResults = analysisResults.filter(
      (result) => result.factor === 'stromverbrauch'
    );

    // Procesar los resultados para manejar entradas con períodos
    let processedResults = [];

    filteredAnalysisResults.forEach((result) => {
      // Si el resultado tiene una 'date', usarlo directamente
      if (result.date) {
        const resultDate = new Date(result.date);
        processedResults.push({
          date: resultDate, // Mantener como objeto Date para facilitar el ordenamiento
          value: Number(result.value),
          co2_aequivalent: Number(result.co2_aequivalent),
        });
      } else if (result.start_date && result.end_date) {
        const start = new Date(result.start_date);
        const end = new Date(result.end_date);
        const totalDays = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
        if (totalDays <= 0) return;

        // Distribuir el valor según el viewMode
        if (viewMode === 'daily') {
          // Distribuir el valor sobre cada día
          let currentDate = new Date(start);
          const dailyValue = Number(result.value) / totalDays;
          const dailyCO2 = Number(result.co2_aequivalent) / totalDays;

          while (currentDate <= end) {
            processedResults.push({
              date: new Date(currentDate), // Mantener como objeto Date
              value: dailyValue,
              co2_aequivalent: dailyCO2,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else if (viewMode === 'monthly') {
          // Distribuir el valor sobre meses
          let currentDate = new Date(start);
          const endMonth = end.getMonth() + end.getFullYear() * 12;
          const startMonth = start.getMonth() + start.getFullYear() * 12;
          const totalMonths = endMonth - startMonth + 1;
          const monthlyValue = Number(result.value) / totalMonths;
          const monthlyCO2 = Number(result.co2_aequivalent) / totalMonths;

          while (currentDate <= end) {
            processedResults.push({
              date: new Date(currentDate), // Mantener como objeto Date
              value: monthlyValue,
              co2_aequivalent: monthlyCO2,
            });
            // Avanzar al siguiente mes
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (viewMode === 'yearly') {
          // Distribuir el valor sobre años
          let currentYear = start.getFullYear();
          const endYear = end.getFullYear();
          const totalYears = endYear - currentYear + 1;
          const yearlyValue = Number(result.value) / totalYears;
          const yearlyCO2 = Number(result.co2_aequivalent) / totalYears;

          while (currentYear <= endYear) {
            processedResults.push({
              date: new Date(`${currentYear}-01-01`), // Mantener como objeto Date
              value: yearlyValue,
              co2_aequivalent: yearlyCO2,
            });
            currentYear++;
          }
        }
      }
    });

    // Filtrar processedResults basado en startDate y endDate
    const filteredResults = processedResults.filter((result) => {
      if (viewMode === 'daily') {
        const isAfterStart = startDate ? result.date >= startDate : true;
        const isBeforeEnd = endDate ? result.date <= endDate : true;
        return isAfterStart && isBeforeEnd;
      } else if (viewMode === 'monthly') {
        // Para mensual, considerar si el mes está dentro del rango
        if (!startDate && !endDate) return true;
        const resultMonth = new Date(result.date.getFullYear(), result.date.getMonth(), 1);
        const startMonth = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), 1) : null;
        const endMonth = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), 1) : null;
        const isAfterStart = startMonth ? resultMonth >= startMonth : true;
        const isBeforeEnd = endMonth ? resultMonth <= endMonth : true;
        return isAfterStart && isBeforeEnd;
      } else if (viewMode === 'yearly') {
        // Para anual, considerar si el año está dentro del rango
        if (!startDate && !endDate) return true;
        const resultYear = result.date.getFullYear();
        const startYear = startDate ? startDate.getFullYear() : null;
        const endYear = endDate ? endDate.getFullYear() : null;
        const isAfterStart = startYear ? resultYear >= startYear : true;
        const isBeforeEnd = endYear ? resultYear <= endYear : true;
        return isAfterStart && isBeforeEnd;
      }
      return true;
    });

    // Ordenar los resultados por fecha ascendente
    const sortedResults = filteredResults.sort((a, b) => a.date - b.date);

    let dates = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      dates = sortedResults.map((result) => result.date.toISOString().split('T')[0]); // 'YYYY-MM-DD'
      values = sortedResults.map((result) => result.value);
      co2Equivalent = sortedResults.map((result) => result.co2_aequivalent);
    } else if (viewMode === 'monthly') {
      const monthlyDataValues = {};
      const monthlyDataCO2 = {};

      sortedResults.forEach((result) => {
        const monthKey = formatMonthYear(result.date); // 'Jan 2023'
        if (!monthlyDataValues[monthKey]) {
          monthlyDataValues[monthKey] = 0;
          monthlyDataCO2[monthKey] = 0;
        }
        monthlyDataValues[monthKey] += result.value;
        monthlyDataCO2[monthKey] += result.co2_aequivalent;
      });

      // Ordenar las claves de los meses por fecha
      const sortedMonths = Object.keys(monthlyDataValues).sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');
        const dateA = new Date(`${monthA} 1, ${yearA}`);
        const dateB = new Date(`${monthB} 1, ${yearB}`);
        return dateA - dateB;
      });

      dates = sortedMonths;
      values = sortedMonths.map((monthKey) => monthlyDataValues[monthKey]);
      co2Equivalent = sortedMonths.map((monthKey) => monthlyDataCO2[monthKey]);
    } else if (viewMode === 'yearly') {
      const yearlyDataValues = {};
      const yearlyDataCO2 = {};

      sortedResults.forEach((result) => {
        const yearKey = result.date.getFullYear().toString(); // '2023'
        if (!yearlyDataValues[yearKey]) {
          yearlyDataValues[yearKey] = 0;
          yearlyDataCO2[yearKey] = 0;
        }
        yearlyDataValues[yearKey] += result.value;
        yearlyDataCO2[yearKey] += result.co2_aequivalent;
      });

      // Ordenar los años
      const sortedYears = Object.keys(yearlyDataValues).sort((a, b) => a - b);

      dates = sortedYears;
      values = sortedYears.map((yearKey) => yearlyDataValues[yearKey]);
      co2Equivalent = sortedYears.map((yearKey) => yearlyDataCO2[yearKey]);
    }

    // Verificar el orden de las fechas
    console.log('Fechas Ordenadas:', dates);
    console.log('Valores:', values);
    console.log('CO₂-Äquivalent:', co2Equivalent);

    if (dates.length === 0) {
      setData({});
      setOptions({});
      return;
    }

    // Calcular los valores máximos para establecer una relación proporcional
    const maxValues = Math.max(...values, 1000); // Asegura al menos 1000 kWh
    const maxTonnes = Math.ceil(Math.max(...co2Equivalent, 10) / 10) * 10; // Redondear al siguiente múltiplo de 10

    // Configurar los datos del gráfico
    const newData = {
      labels: dates,
      datasets: [
        {
          label: 'Stromverbrauch (kWh)',
          data: values,
          fill: true,
          backgroundColor: 'rgba(0, 93, 165, 0.8)', // Azul fuerte con menos transparencia
          borderColor: 'rgba(0, 93, 165, 1)',       // Azul fuerte sin transparencia
          borderWidth: 2,
          borderRadius: 5,
          yAxisID: 'y',
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value) => `${Math.round(value).toLocaleString()} kWh`,
            color: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
          },
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(0, 166, 117, 0.8)', // Verde fuerte con menos transparencia
          borderColor: 'rgba(0, 128, 0, 1)',         // Verde fuerte sin transparencia
          borderWidth: 2,
          borderRadius: 5,
          yAxisID: 'y1',
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value) => (value < 10 ? `${value.toFixed(2)} t` : `${Math.round(value)} t`),
            color: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
          },
          hidden: true, // Ocultar por defecto el consumo
        },
      ],
    };

    // Configurar las opciones del gráfico
    const newOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        // Registrar el plugin de data labels
        datalabels: {
          display: true,
        },
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#f5f5f5',
          titleColor: '#333',
          bodyColor: '#555',
          borderColor: '#ccc',
          borderWidth: 1,
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += context.dataset.yAxisID === 'y' 
                  ? `${Math.round(context.parsed.y).toLocaleString()} kWh`
                  : `${context.parsed.y < 10 ? context.parsed.y.toFixed(2) : Math.round(context.parsed.y)} t`;
              }
              return label;
            }
          }
        },
        title: {
          display: true,
          text: 'Stromverbrauch und CO₂-Äquivalent',
          font: {
            size: 32,
            family: 'Arial, sans-serif',
            weight: 'bold',
          },
          color: '#333',
          padding: {
            top: 20,
            bottom: 10,
          },
        },
      },
      scales: {
        x: {
          type: 'category', // Asegura que el eje X trate las etiquetas como categorías
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
            maxRotation: 45, // Rotar etiquetas si es necesario
            minRotation: 45,
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          min: 0,
          max: Math.ceil(maxValues / 1000) * 1000, // Redondear al siguiente múltiplo de 1000
          title: {
            display: true,
            text: 'kWh',
            font: {
              size: 24,
              family: 'Arial, sans-serif',
              weight: 'bold',
            },
            color: '#333',
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
            callback: function (value) {
              return `${Math.round(value).toLocaleString()} kWh`;
            },
            maxTicksLimit: 5, // Limitar el número de ticks para mejorar la legibilidad
            stepSize: Math.ceil(maxValues / 1000) * 1000 / 5, // Asegurar pasos uniformes
          },
          grid: {
            drawOnChartArea: true,
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          min: 0,
          max: maxTonnes, // Redondear al siguiente múltiplo de 10
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
            font: {
              size: 24,
              family: 'Arial, sans-serif',
              weight: 'bold',
            },
            color: '#333',
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
            callback: function (value) {
              // Formatear con 2 decimales si es menor que 10, de lo contrario sin decimales
              return value < 10 ? `${value.toFixed(2)} t` : `${Math.round(value)} t`;
            },
            maxTicksLimit: 5, // Limitar el número de ticks para mejorar la legibilidad
            stepSize: maxTonnes / 5, // Asegurar pasos uniformes
          },
          grid: {
            drawOnChartArea: false, // Ocultar las líneas de la cuadrícula para el eje y1
          },
        },
      },
      layout: {
        padding: {
          left: 50, // Ajusta según necesites
          right: 50, // Ajusta según necesites
        },
      },
    };

    // Actualizar los estados
    setData(newData);
    setOptions(newOptions);

    // Depuración: Verificar que 'data' y 'options' están definidos
    console.log('Data Configurada:', newData);
    console.log('Options Configuradas:', newOptions);
  }, [analysisResults, viewMode, startDate, endDate, formatMonthYear]);

  // Función para descargar el gráfico en el formato seleccionado
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image(); // Siempre descarga como 'png'
    const link = document.createElement('a');
    link.href = url;
    link.download = `stromverbrauch_co2.${downloadFormat}`;
    link.click();
  };

  // Función para compartir el gráfico
  const handleShare = async () => {
    if (!data || Object.keys(data).length === 0) {
      alert('La configuración del gráfico no está lista para compartir.');
      return;
    }

    setIsLoading(true);

    try {
      const chartConfig = {
        type: chartType, // Tipo de gráfico global
        data: data,
        options: options,
        plugins: [ChartDataLabels], // Registrar el plugin
      };

      // Serializar la configuración del gráfico
      const configString = JSON.stringify(chartConfig);
      if (!configString) {
        throw new Error('Error al serializar la configuración del gráfico.');
      }

      const encodedConfig = encodeURIComponent(configString);
      const quickChartUrl = `https://quickchart.io/chart?c=${encodedConfig}&width=1920&height=1080&format=png&backgroundColor=white`;

      // Opcional: Verificar el URL generado
      console.log('QuickChart URL:', quickChartUrl);

      // Acortar la URL con TinyURL
      const tinyUrlResponse = await axios.get(`https://tinyurl.com/api-create.php?url=${encodeURIComponent(quickChartUrl)}`);

      const shortUrl = tinyUrlResponse.data;

      // Copiar la URL acortada al portapapeles
      await navigator.clipboard.writeText(shortUrl);

      // Guardar la URL en el estado para mostrar en el diálogo
      setShareLink(shortUrl);

      // Abrir el diálogo
      setOpen(true);
    } catch (error) {
      console.error('Error al compartir el enlace:', error);
      alert('Hubo un problema al intentar compartir el gráfico. Por favor, inténtelo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  // Función para cerrar el diálogo de compartir
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Gráfico con mayor altura y separación */}
      {data.labels && data.labels.length > 0 ? (
        <div className="chart-container" style={{ height: '80vh', marginBottom: '40px' }}>
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <p>Für den gewählten Datumsbereich sind keine Daten verfügbar.</p>
      )}

      {/* Controles con margen inferior */}
      <div style={{ marginBottom: '20px' }}>
        <ControlsComponent
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>

      {/* Botón para compartir el gráfico */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleShare}
        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <ShareIcon />}
        disabled={isLoading}
        style={{ marginBottom: '20px' }}
      >
        {isLoading ? 'Teilen...' : 'Grafik teilen'}
      </Button>

      {/* Diálogo para mostrar el enlace y el QR */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Grafik teilen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Kopiere den folgenden Link oder scanne den QR-Code:</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={shareLink}
            InputProps={{
              readOnly: true,
            }}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCodeSVG
              value={shareLink}
              size={300}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="H"
              includeMargin={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Stromverbrauch;
