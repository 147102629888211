// ./src/pages/Papierverbrauch.js
import ShareIcon from '@mui/icons-material/Share';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react'; // Para mostrar el código QR
import React, { useEffect, useRef, useState } from 'react';
import ChartComponent from '../components/ChartComponent';
import ControlsComponent from '../components/ControlsComponent';
// Importar el plugin de data labels
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Papierverbrauch = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewMode, setViewMode] = useState('daily');
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});
  const [chartType, setChartType] = useState('line');
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  // Estado para almacenar los resultados de análisis
  const [analysisResults, setAnalysisResults] = useState([]);

  // Estados para la funcionalidad de compartir
  const [shareLink, setShareLink] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Función para obtener los datos desde la BD
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Obtener los datos al montar el componente
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Procesar los datos cada vez que cambien los filtros o los resultados
  useEffect(() => {
    const filteredAnalysisResults = analysisResults.filter(result => result.factor === 'papierverbrauch');

    const sortedResults = [...filteredAnalysisResults].sort((a, b) => new Date(a.date) - new Date(b.date));

    const filteredResults = sortedResults.filter((result) => {
      const resultDate = new Date(result.date);
      const isAfterStart = startDate ? resultDate >= startDate : true;
      const isBeforeEnd = endDate ? resultDate <= endDate : true;
      return isAfterStart && isBeforeEnd;
    });

    let dates = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      dates = filteredResults.map(result => result.date);
      values = filteredResults.map(result => Number(result.value)); // Extraer el valor desde "value"
      co2Equivalent = filteredResults.map(result => Number(result.co2_aequivalent));
    } else if (viewMode === 'monthly') {
      const monthlyDataValues = {};
      const monthlyDataCO2 = {};

      filteredResults.forEach(result => {
        const date = new Date(result.date);
        const month = date.toLocaleString('de-DE', { month: 'short', year: 'numeric' });
        if (!monthlyDataValues[month]) {
          monthlyDataValues[month] = 0;
          monthlyDataCO2[month] = 0;
        }
        monthlyDataValues[month] += Number(result.value); // Sumar valores de "value"
        monthlyDataCO2[month] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(monthlyDataValues);
      values = Object.values(monthlyDataValues);
      co2Equivalent = Object.values(monthlyDataCO2);
    } else if (viewMode === 'yearly') {
      const yearlyDataValues = {};
      const yearlyDataCO2 = {};

      filteredResults.forEach(result => {
        const date = new Date(result.date);
        const year = date.getFullYear();
        if (!yearlyDataValues[year]) {
          yearlyDataValues[year] = 0;
          yearlyDataCO2[year] = 0;
        }
        yearlyDataValues[year] += Number(result.value); // Sumar valores de "value"
        yearlyDataCO2[year] += Number(result.co2_aequivalent);
      });

      dates = Object.keys(yearlyDataValues);
      values = Object.values(yearlyDataValues);
      co2Equivalent = Object.values(yearlyDataCO2);
    }

    setData({
      labels: dates,
      datasets: [
        {
          label: 'Papierverbrauch (kg)',
          data: values,
          fill: true,
          backgroundColor: 'rgba(255,206,86,0.2)',
          borderColor: 'rgba(255,206,86,1)',
          tension: 0.4,
          yAxisID: 'y',
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value) => `${value} kg`,
            color: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
          },
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(54,162,235,0.2)',
          borderColor: 'rgba(54,162,235,1)',
          tension: 0.4,
          yAxisID: 'y1',
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value) => `${value} t`,
            color: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
          },
        },
      ],
    });

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        // Registrar el plugin de data labels
        datalabels: {
          display: true,
        },
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: '#f5f5f5',
          titleColor: '#333',
          bodyColor: '#555',
          borderColor: '#ccc',
          borderWidth: 1,
        },
        title: {
          display: true,
          text: 'Papierverbrauch und CO₂-Äquivalent',
          font: {
            size: 32,
            family: 'Arial, sans-serif',
            weight: 'bold',
          },
          color: '#333',
          padding: {
            top: 20,
            bottom: 10,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'kg',
            font: {
              size: 24,
              family: 'Arial, sans-serif',
              weight: 'bold',
            },
            color: '#333',
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
            callback: function(value) {
              return value + ' kg';
            },
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
            font: {
              size: 24,
              family: 'Arial, sans-serif',
              weight: 'bold',
            },
            color: '#333',
          },
          ticks: {
            font: {
              size: 18,
              family: 'Arial, sans-serif',
            },
            color: '#333',
            callback: function(value) {
              return value + ' t';
            },
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    });
  }, [analysisResults, startDate, endDate, viewMode]);

  // Función para compartir el gráfico
  const handleShare = async () => {
    if (!data || !options) {
      alert('La configuración del gráfico no está lista para compartir.');
      return;
    }

    setIsLoading(true);

    try {
      const chartConfig = {
        type: chartType,
        data: data,
        options: options,
        plugins: [ChartDataLabels], // Registrar el plugin
      };

      // Verificar que 'options' está definido
      if (!chartConfig.options) {
        throw new Error('Las opciones del gráfico están indefinidas.');
      }

      // Serializar la configuración del gráfico
      const configString = JSON.stringify(chartConfig);
      if (!configString) {
        throw new Error('Error al serializar la configuración del gráfico.');
      }

      const encodedConfig = encodeURIComponent(configString);
      const quickChartUrl = `https://quickchart.io/chart?c=${encodedConfig}&width=1920&height=1080&format=png&backgroundColor=white`;

      // Opcional: Verificar el URL generado
      console.log('QuickChart URL:', quickChartUrl);

      // Verificar que la URL es válida
      if (!quickChartUrl) {
        throw new Error('La URL generada para QuickChart es inválida.');
      }

      // Acortar la URL con TinyURL
      const tinyUrlResponse = await axios.get(`https://tinyurl.com/api-create.php?url=${encodeURIComponent(quickChartUrl)}`);

      const shortUrl = tinyUrlResponse.data;

      // Copiar la URL acortada en el clipboard
      await navigator.clipboard.writeText(shortUrl);

      // Guardar la URL en el estado para mostrar en el diálogo
      setShareLink(shortUrl);

      // Abrir el diálogo
      setOpen(true);
    } catch (error) {
      console.error('Error al compartir el enlace:', error);
      alert('Hubo un problema al intentar compartir el gráfico. Por favor, inténtelo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  // Función para descargar el gráfico
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image(); // Siempre descarga como 'png'
    const link = document.createElement('a');
    link.href = url;
    link.download = `papierverbrauch_co2.png`;
    link.click();
  };

  // Función para cerrar el diálogo de compartir
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Gráfico con mayor altura y separación */}
      {data.labels && data.labels.length > 0 ? (
        <div className="chart-container" style={{ height: '80vh', marginBottom: '40px' }}>
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <p>Für den gewählten Datumsbereich sind keine Daten verfügbar.</p>
      )}

      {/* Controles con margen inferior */}
      <div style={{ marginBottom: '20px' }}>
        <ControlsComponent
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>

      {/* Botón para compartir el gráfico */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleShare}
        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <ShareIcon />}
        disabled={isLoading}
        style={{ marginBottom: '20px' }}
      >
        {isLoading ? 'Teilen...' : 'Grafik teilen'}
      </Button>

      {/* Diálogo para mostrar el enlace y el QR */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Grafik teilen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Kopiere den folgenden Link o escanea el código QR:</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={shareLink}
            InputProps={{
              readOnly: true,
            }}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCodeSVG
              value={shareLink}
              size={300}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="H"
              includeMargin={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Papierverbrauch;
