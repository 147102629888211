import 'boxicons/css/boxicons.min.css';
import React from 'react';
import '../styles/dashboard.css';

const Dashboard = ({ onNavigate }) => {
  return (
    <div className="esg-container">
      <header className="esg-header">
        <span className="esg-badge">Enterprise ESG-Plattform</span>
        <h1 className="esg-title">LIKS Suite</h1>
        <p className="esg-subtitle">Umfassende ESG-Lösungen für moderne Unternehmen</p>
      </header>

      <div className="esg-grid">
        {/* Tarjeta 1: Umweltanalysen */}
        <div className="esg-card">
          <div className="esg-icon emerald">
            <i className='bx bx-bar-chart-alt-2' style={{ color: 'white', fontSize: '2rem' }}></i>
          </div>
          <h2 className="esg-card-title">Umweltanalysen</h2>
          <p className="esg-card-description">
            Fortschrittliche Analysen und Erkenntnisse zur Bewertung und Überwachung von Umweltauswirkungen
          </p>
          <button
            className="esg-button emerald"
            onClick={() => onNavigate('analyse')}
          >
            Jetzt entdecken
            <i className='bx bx-right-arrow-alt'></i>
          </button>
        </div>

        {/* Tarjeta 2: Social */}
        <div className="esg-card">
          <div className="esg-icon blue">
            <i className='bx bx-group' style={{ color: 'white', fontSize: '2rem' }}></i>
          </div>
          <h2 className="esg-card-title">Soziales</h2>
          <p className="esg-card-description">
            Werkzeuge und Analysen zur Förderung sozialer Verantwortung und Engagement
          </p>
          <button
            className="esg-button blue"
            onClick={() => onNavigate('social')}
          >
            Mehr erfahren
            <i className='bx bx-right-arrow-alt'></i>
          </button>
        </div>

        {/* Tarjeta 3: Governance */}
        <div className="esg-card">
          <div className="esg-icon purple">
            <i className='bx bx-shield-quarter' style={{ color: 'white', fontSize: '2rem' }}></i>
          </div>
          <h2 className="esg-card-title">Governance</h2>
          <p className="esg-card-description">
            Lösungen zur Verbesserung der Unternehmensführung und Compliance
          </p>
          <button
            className="esg-button purple"
            onClick={() => onNavigate('governance')}
          >
            Jetzt starten
            <i className='bx bx-right-arrow-alt'></i>
          </button>
        </div>
      </div>

      <footer className="esg-footer">
        <p>© 2024 LIKS GmbH. Alle Rechte vorbehalten.</p>
      </footer>
    </div>
  );
};

export default Dashboard;