import axios from 'axios';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import '../styles/Ubersicht.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement);

const Ubersicht = () => {
  const [emissionsData, setEmissionsData] = useState({
    scope1: 0,
    scope2: 0,
    scope3: 0
  });
  const [socialData, setSocialData] = useState(null);
  const [governanceData, setGovernanceData] = useState(null);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [, setGesamtEmissionen] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const [analysisResponse, gesamtResponse] = await Promise.all([
          axios.get(`${backendUrl}/analyse/analysis-results/`),
          axios.get(`${backendUrl}/analyse/gesamt-emissionen/`)
        ]);
        setAnalysisResults(analysisResponse.data.results);
        setGesamtEmissionen(gesamtResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

    // Load social and governance data from localStorage
    const storedSocialData = localStorage.getItem('liks_social_data');
    const storedGovernanceData = localStorage.getItem('liks_governance_data');
    
    if (storedSocialData) setSocialData(JSON.parse(storedSocialData));
    if (storedGovernanceData) setGovernanceData(JSON.parse(storedGovernanceData));
  }, []);

  useEffect(() => {
    // Calculate emissions for each scope
    const scope1Factors = ['benzin', 'diesel', 'heizoel'];
    const scope2Factors = ['stromverbrauch', 'erdgas'];
    const scope3Factors = [
      'wasserverbrauch',
      'erzeugte_abfaelle',
      'geschaeftsreisen',
      'papierverbrauch',
      'kunststoffproduktion',
      'kaeltemittel',
      'transport_produkte',
      'verbrauch_landwirtschaftliche_produkte'
    ];

    const calculateScopeEmissions = (factors) => {
      return analysisResults
        .filter(result => factors.includes(result.factor))
        .reduce((sum, result) => sum + Number(result.co2_aequivalent), 0);
    };

    setEmissionsData({
      scope1: calculateScopeEmissions(scope1Factors),
      scope2: calculateScopeEmissions(scope2Factors),
      scope3: calculateScopeEmissions(scope3Factors)
    });
  }, [analysisResults]);

 

  const socialChartData = socialData ? {
    labels: ['Männlich', 'Weiblich', 'Divers'],
    datasets: [{
      data: [socialData.male, socialData.female, socialData.diverse],
      backgroundColor: [
        'rgba(59, 130, 246, 0.8)',
        'rgba(236, 72, 153, 0.8)',
        'rgba(16, 185, 129, 0.8)'
      ],
      borderWidth: 1
    }]
  } : null;

  const governanceChartData = governanceData ? {
    labels: ['Unabhängige', 'Weiblich', 'Divers'],
    datasets: [{
      data: [
        governanceData.boardMembers.independent,
        governanceData.boardMembers.female,
        governanceData.boardMembers.diverse
      ],
      backgroundColor: [
        'rgba(59, 130, 246, 0.8)',
        'rgba(236, 72, 153, 0.8)',
        'rgba(16, 185, 129, 0.8)'
      ],
      borderWidth: 1
    }]
  } : null;

  return (
    <div className="ubersicht-container">
      <motion.div 
        className="ubersicht-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, staggerChildren: 0.1 }}
      >
        <h1>ESG Übersicht</h1>
        <p>Ganzheitlicher Überblick über Ihre ESG-Performance</p>
      </motion.div>

      <div className="metrics-grid">
        {/* GesamtEmissionen Card */}
        <motion.div 
          className="metric-card gesamt-emissionen"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          layout
        >
          <h2>Gesamte CO₂-Emissionen</h2>
          <div className="emissions-overview">
            <div className="total-value">
              {((emissionsData.scope1 + emissionsData.scope2 + emissionsData.scope3) || 0).toFixed(2)}
              <span className="unit">Tonnen CO₂e</span>
            </div>
            <div className="emissions-breakdown">
              <div className="breakdown-item">
                <span className="label">Scope 1</span>
                <div className="value">{emissionsData.scope1.toFixed(2)} t</div>
              </div>
              <div className="breakdown-item">
                <span className="label">Scope 2</span>
                <div className="value">{emissionsData.scope2.toFixed(2)} t</div>
              </div>
              <div className="breakdown-item">
                <span className="label">Scope 3</span>
                <div className="value">{emissionsData.scope3.toFixed(2)} t</div>
              </div>
            </div>
          </div>
        </motion.div>


        <motion.div 
          className="metric-card social"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2>Soziale Kennzahlen</h2>
          {socialData ? (
            <>
              <div className="chart-container">
                <Doughnut 
                  data={socialChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          padding: 20,
                          font: { size: 14 }
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className="social-stats">
                <div className="stat">
                  <span className="label">Mitarbeiter:innen</span>
                  <span className="value">{socialData.total}</span>
                </div>
                <div className="stat">
                  <span className="label">Ø Alter</span>
                  <span className="value">{socialData.avgAge} Jahre</span>
                </div>
                <div className="stat">
                  <span className="label">Weiterbildung</span>
                  <span className="value">{socialData.trainingHours}h</span>
                </div>
              </div>
            </>
          ) : (
            <div className="no-data">Keine Sozialdaten verfügbar</div>
          )}
        </motion.div>

        <motion.div 
          className="metric-card governance"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <h2>Governance Kennzahlen</h2>
          {governanceData ? (
            <>
              <div className="chart-container">
                <Doughnut 
                  data={governanceChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          padding: 20,
                          font: { size: 14 }
                        }
                      }
                    }
                  }}
                />
              </div>
              <div className="governance-stats">
                <div className="stat">
                  <span className="label">Vorstandsmitglieder</span>
                  <span className="value">{governanceData.boardMembers.total}</span>
                </div>
                <div className="stat">
                  <span className="label">Compliance-Rate</span>
                  <span className="value">{governanceData.compliance.resolutionRate}%</span>
                </div>
                <div className="stat">
                  <span className="label">Schulungsstunden</span>
                  <span className="value">{governanceData.compliance.trainingHours}h</span>
                </div>
              </div>
            </>
          ) : (
            <div className="no-data">Keine Governance-Daten verfügbar</div>
          )}
        </motion.div>

      </div>
    </div>
  );
};

export default Ubersicht;