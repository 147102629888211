// Berichte.js
import React, { useState } from 'react';
import '../styles/Berichte.css';

const Berichte = () => {
  const [showPDF, setShowPDF] = useState(false);

  const handleCardClick = () => {
    setShowPDF(true);
  };

  const handleClosePDF = () => {
    setShowPDF(false);
  };

  return (
    <div className="berichte-container">
      {/* Tarjeta para ESG Bericht */}
      <div className="berichte-card" onClick={handleCardClick}>
        <div className="berichte-card-body">
          <span>ESG Bericht LIKS 2022</span>
          <i className="bi bi-chevron-right"></i>
        </div>
      </div>

      {/* Visor de PDF */}
      {showPDF && (
        <div className="pdf-container">
          <iframe
            src="/pdfs/ESG_Bericht_LIKS_2022.pdf"
            title="ESG Bericht LIKS 2022"
            width="100%"
            height="100%"
            style={{ border: 'none', minHeight: '80vh' }}
          ></iframe>
          <button className="close-button" onClick={handleClosePDF}>
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default Berichte;
