import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaKey, FaTrash } from 'react-icons/fa';
import '../styles/GlobaleEinstellungen.css';

import { changeEmail, changePassword } from '../utils/auth0Utils';

const GlobaleEinstellungen = () => {
  const { user } = useAuth0();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const email = user?.email || '';
  const nickname = user?.nickname || '';
  const [licenseProgress, setLicenseProgress] = useState(0);

  useEffect(() => {
    // Dividir el nombre completo en nombre y apellido
    if (user && user.name) {
      const [first, ...last] = user.name.split(' ');
      setFirstName(first);
      setLastName(last.join(' '));
    }

    // Calcular el porcentaje de progreso de la licencia
    const startDate = new Date('2024-12-01');
    const endDate = new Date('2025-12-01');
    const currentDate = new Date();

    const totalDuration = endDate - startDate;
    const elapsed = currentDate - startDate;
    const progress = Math.max(0, Math.min(100, (elapsed / totalDuration) * 100));

    setLicenseProgress(progress);
  }, [user]);

  const handleDeleteAnalysisData = async () => {
    if (window.confirm('Sind Sie sicher, dass Sie alle Analysedaten löschen möchten?')) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.delete(`${backendUrl}/analyse/delete-analysis-data/`);
        alert(response.data.message);
      } catch (error) {
        console.error('Fehler beim Löschen der Analysedaten:', error);
        alert('Fehler beim Löschen der Analysedaten. Bitte versuchen Sie es erneut.');
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      const ticket = await changePassword(email);
      window.open(ticket, '_blank');
    } catch (error) {
      console.error('Fehler beim Ändern des Passworts:', error.response.data);
      alert(`Fehler beim Ändern des Passworts: ${error.response.data.message}`);
    }
  };

  const handleChangeEmail = async () => {
    if (!newEmail) {
      alert('Bitte geben Sie eine neue E-Mail-Adresse ein.');
      return;
    }
    try {
      const userId = user?.sub; // El ID del usuario en Auth0
      await changeEmail(userId, newEmail);
      alert('E-Mail-Adresse erfolgreich geändert. Bitte bestätigen Sie Ihre neue E-Mail-Adresse.');
    } catch (error) {
      console.error('Fehler beim Ändern der E-Mail-Adresse:', error.response.data);
      alert(`Fehler beim Ändern der E-Mail-Adresse: ${error.response.data.message}`);
    }
  };

  return (
    <div className="settings-container">
      <motion.div
        className="license-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="license-header">
          <div className="license-type">Partner Lizenz</div>
          <div className="license-dates">
            Gültig vom 01.12.2024 bis 01.12.2025
          </div>
          {/* Botón para descargar el contrato */}
          <div className="license-download">
            <a href="/contract.pdf" className="download-button" download>
              Vertrag herunterladen
            </a>
          </div>
        </div>
        <div className="license-progress-container">
          <div
            className="license-progress-bar"
            style={{ width: `${licenseProgress}%` }}
          />
        </div>
        <div style={{ color: '#666', marginTop: '1rem' }}>
          {Math.round(licenseProgress)}% der Lizenzlaufzeit verstrichen
        </div>
      </motion.div>

      <motion.div
        className="settings-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Globale Einstellungen</h1>
        <p>Verwalten Sie Ihre Benutzereinstellungen und Datenschutzoptionen</p>
      </motion.div>

      <div className="settings-content">
        <motion.div
          className="settings-section user-profile"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2>Benutzerprofil</h2>
          <div className="settings-card">
            <div className="form-group">
              <label>Vorname</label>
              <input type="text" value={firstName} disabled />
            </div>
            <div className="form-group">
              <label>Nachname</label>
              <input type="text" value={lastName} disabled />
            </div>
            <div className="form-group">
              <label>Benutzername</label>
              <input type="text" value={nickname} disabled />
            </div>
          </div>
        </motion.div>

        <motion.div
          className="settings-section email-settings"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <h2>E-Mail Einstellungen</h2>
          <div className="settings-card">
            <div className="form-group">
              <label>Aktuelle E-Mail-Adresse</label>
              <input type="email" value={email} disabled />
            </div>
            <div className="form-group">
              <label>Neue E-Mail-Adresse</label>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </div>
            <button className="settings-button" onClick={handleChangeEmail}>
              E-Mail-Adresse ändern
            </button>
          </div>
        </motion.div>

        <motion.div
          className="settings-section security"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2>Sicherheit</h2>
          <div className="settings-card">
            <button className="settings-button" onClick={handleChangePassword}>
              <FaKey className="button-icon" />
              Passwort ändern
            </button>
          </div>
        </motion.div>

        <motion.div
          className="settings-section data-management"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <h2>Datenverwaltung</h2>
          <div className="settings-card danger-zone">
            <div className="danger-zone-header">
              <FaTrash className="danger-icon" />
              <h3>Gefahrenzone</h3>
            </div>
            <p className="danger-description">
              Das Löschen der Analysedaten kann nicht rückgängig gemacht werden.
              Stellen Sie sicher, dass Sie ein Backup haben, falls Sie die Daten später benötigen.
            </p>
            <button className="danger-button" onClick={handleDeleteAnalysisData}>
              Alle Analysedaten löschen
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default GlobaleEinstellungen;