// Wesentlichkeitsanalyse.js
import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bubble } from 'react-chartjs-2';
import { FaPlus } from 'react-icons/fa';
import '../styles/Wesentlichkeitsanalyse.css';


ChartJS.register(LinearScale, PointElement, Tooltip, Legend, Title);

const Wesentlichkeitsanalyse = () => {
  const handlePlusClick = () => {
    alert(
      'In dieser Demo-Version können Sie keine Wesentlichkeitsanalyse erstellen. Bitte beantragen Sie eine Lizenz, um diese Funktion nutzen zu können.'
    );
  };

  const data = {
    datasets: [
      {
        label: 'Umweltthemen',
        data: [
          { x: 6, y: 9, r: 15, label: 'Klimawandel / Treibhausgase' },
          { x: 8, y: 8, r: 12, label: 'Energieverbrauch' },
          { x: 5, y: 7, r: 10, label: 'Luftqualität' },
          { x: 7, y: 10, r: 14, label: 'Biodiversität' },
          { x: 4, y: 9, r: 13, label: 'Waldverlust' },
          { x: 3, y: 8, r: 10, label: 'Ressourcennutzung' },
          { x: 9, y: 5, r: 11, label: 'Wasserknappheit' },
        ],
        backgroundColor: 'rgba(52, 199, 89, 0.6)', // Apple-style green
        borderColor: 'rgba(52, 199, 89, 1)',
        borderWidth: 1,
      },
      {
        label: 'Soziale Themen',
        data: [
          { x: 4, y: 3, r: 10, label: 'Arbeitsbedingungen' },
          { x: 7, y: 4, r: 12, label: 'Gemeinschaftsengagement' },
          { x: 8, y: 5, r: 18, label: 'Unternehmenskultur' },
          { x: 6, y: 6, r: 14, label: 'Datenschutz' },
          { x: 3, y: 4, r: 9, label: 'Mitarbeiterzufriedenheit' },
          { x: 5, y: 2, r: 11, label: 'Vielfalt und Inklusion' },
          { x: 2, y: 6, r: 13, label: 'Bildung und Training' },
          { x: 4, y: 5, r: 10, label: 'Gesundheit und Sicherheit' },
        ],
        backgroundColor: 'rgba(0, 122, 255, 0.6)', // Apple-style blue
        borderColor: 'rgba(0, 122, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'Governance Themen',
        data: [
          { x: 5, y: 8, r: 12, label: 'Korruption / Bestechung' },
          { x: 6, y: 7, r: 10, label: 'Schutz von Whistleblowern' },
          { x: 7, y: 9, r: 15, label: 'Lobbyismus' },
          { x: 4, y: 8, r: 8, label: 'Rechtsvorschriften' },
          { x: 5, y: 5, r: 13, label: 'Transparenz in der Lieferkette' },
          { x: 3, y: 9, r: 10, label: 'Unternehmensethik' },
          { x: 8, y: 3, r: 14, label: 'Vorstandsunabhängigkeit' },
          { x: 6, y: 4, r: 12, label: 'Compliance-Programme' },
        ],
        backgroundColor: 'rgba(88, 86, 214, 0.6)', // Apple-style purple
        borderColor: 'rgba(88, 86, 214, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Importante para que el gráfico sea responsivo
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Bedeutung für Stakeholder',
          font: {
            size: 14,
            family: 'SF Pro Display, sans-serif',
            weight: '500',
          },
          color: '#1c1c1e',
        },
        min: 0,
        max: 12,
        ticks: {
          beginAtZero: true,
          color: '#1c1c1e',
        },
        grid: {
          drawTicks: true,
          drawBorder: true,
          color: '#d1d1d6',
          lineWidth: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Auswirkungen auf die Organisation',
          font: {
            size: 14,
            family: 'SF Pro Display, sans-serif',
            weight: '500',
          },
          color: '#1c1c1e',
        },
        min: 0,
        max: 12,
        ticks: {
          beginAtZero: true,
          color: '#1c1c1e',
        },
        grid: {
          drawTicks: true,
          drawBorder: true,
          color: '#d1d1d6',
          lineWidth: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.raw.label}: Bedeutung für Stakeholder (${context.raw.x}), Auswirkungen auf Organisation (${context.raw.y})`,
        },
        titleFont: {
          family: 'SF Pro Display, sans-serif',
          weight: '500',
        },
        bodyFont: {
          family: 'SF Pro Display, sans-serif',
          weight: '400',
        },
        backgroundColor: '#f2f2f7',
        titleColor: '#1c1c1e',
        bodyColor: '#1c1c1e',
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            family: 'SF Pro Display, sans-serif',
            weight: '500',
          },
          color: '#1c1c1e',
        },
      },
      title: {
        display: true,
        text: 'Wesentlichkeitsanalyse-Matrix (Umwelt, Soziales, Governance)',
        font: {
          size: 18,
          family: 'SF Pro Display, sans-serif',
          weight: '600',
        },
        color: '#1c1c1e',
      },
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
    },
  };

  return (
    <div className="wesentlichkeitsanalyse-container">
      {/* Botón en la esquina superior derecha */}
      <div className="top-right">
        <button className="custom-btn" onClick={handlePlusClick}>
          <FaPlus className="plus-icon" /> Wesentlichkeitsanalyse erstellen
        </button>
      </div>

      {/* Contenedor del gráfico */}
      <div className="chart-container">
        <Bubble data={data} options={options} />
      </div>

      {/* Tarjeta de explicación */}
      <div className="explanation-card">
        <h3>Wesentlichkeitsanalyse: Bedeutung und Methodik</h3>
        <p>
          Die Wesentlichkeitsanalyse ist ein wesentlicher Bestandteil der Nachhaltigkeitsstrategie eines Unternehmens. Sie ermöglicht es, die Themen zu identifizieren, die sowohl für die Stakeholder des Unternehmens als auch für das Unternehmen selbst am wichtigsten sind.
          In der Matrix sind die Themen entlang der beiden Achsen dargestellt: Die x-Achse zeigt die <strong>Bedeutung für Stakeholder</strong> und die y-Achse die <strong>Auswirkungen auf die Organisation</strong>. Die Größe der Kreise repräsentiert die Relevanz und Priorität des jeweiligen Themas.
        </p>
        <h4>Methodik der Wesentlichkeitsanalyse</h4>
        <p>
          Die Wesentlichkeitsanalyse wird durch eine Kombination von internen und externen Bewertungen durchgeführt. Dies beinhaltet eine Umfrage unter den wichtigsten Stakeholdern, um deren Erwartungen und Prioritäten zu verstehen, sowie eine interne Bewertung, die sich auf die strategischen Ziele des Unternehmens bezieht.
          Die Analyse hilft, sowohl Risiken als auch Chancen zu identifizieren, die das Unternehmen beeinflussen könnten. Die Themen, die in der Matrix oben aufgeführt sind, wurden nach ihrer Bedeutung sowohl für die Stakeholder als auch für das Unternehmen priorisiert.
        </p>
        <h4>Skalierung der Bedeutung und Auswirkungen</h4>
        <p>
          Die Achsen der Matrix reichen von 0 bis 12 und stellen jeweils die Bedeutung für Stakeholder (x-Achse) und die finanziellen bzw. organisatorischen Auswirkungen (y-Achse) dar. Eine höhere Zahl zeigt eine größere Bedeutung oder Auswirkung an.
          Zum Beispiel bedeutet ein Wert von 10 bis 12 auf der x-Achse, dass das Thema für die Stakeholder von sehr hoher Wichtigkeit ist, während ein entsprechender Wert auf der y-Achse die erheblichen Auswirkungen des Themas auf die Organisation widerspiegelt. Diese Skalierung hilft dabei, die Themen zu priorisieren, die für die nachhaltige Entwicklung des Unternehmens am relevantesten sind.
        </p>
        <p>
          Durch die Wesentlichkeitsanalyse wird sichergestellt, dass die Ressourcen des Unternehmens effizient zugewiesen werden und strategische Maßnahmen fokussiert auf die wesentlichen Themen gesetzt werden, die den größten positiven Einfluss auf das Unternehmen, die Stakeholder und die Umwelt haben können.
        </p>
      </div>
    </div>
  );
};

export default Wesentlichkeitsanalyse;


