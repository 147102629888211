import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import '../styles/Scope.css';

const Scope1 = ({ onNavigate }) => {
  const [data, setData] = useState({});
  const [analysisResults, setAnalysisResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
        setAnalysisResults(response.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter Scope 1 factors
    const scope1Factors = ['benzin', 'diesel', 'heizoel'];
    const scope1Results = analysisResults.filter(result => 
      scope1Factors.includes(result.factor)
    );

    // Calculate total emissions per factor
    const totalEmissions = scope1Factors.map(factor => {
      const factorResults = scope1Results.filter(result => result.factor === factor);
      return {
        factor,
        total: factorResults.reduce((sum, result) => sum + Number(result.co2_aequivalent), 0)
      };
    });

    // Calculate total Scope 1 emissions
    const totalScope1Emissions = totalEmissions.reduce((sum, item) => sum + item.total, 0);
    setData({ totalEmissions: totalScope1Emissions, factorEmissions: totalEmissions });

  }, [analysisResults]);

  const factors = [
    {
      name: 'Benzinverbrauch',
      route: 'benzinverbrauch',
      description: 'Direkter Kraftstoffverbrauch von Fahrzeugen'
    },
    {
      name: 'Dieselverbrauch',
      route: 'dieselverbrauch',
      description: 'Kraftstoffverbrauch von Dieselfahrzeugen'
    },
    {
      name: 'Heizölverbrauch',
      route: 'heizoel',
      description: 'Verbrauch von Heizöl für Gebäudeheizung'
    }
  ];

  return (
    <div className="scope-container">
      <motion.div 
        className="scope-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Scope 1 Emissionen</h1>
        <p>Direkte Treibhausgasemissionen aus unternehmenseigenen oder kontrollierten Quellen</p>
      </motion.div>

      <motion.div 
        className="emissions-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="emissions-label">Gesamte Scope 1 Emissionen</div>
        <div className="emissions-value">
          {data.totalEmissions ? data.totalEmissions.toFixed(2) : '0.00'}
        </div>
        <div className="emissions-period">Tonnen CO₂-Äquivalent</div>
      </motion.div>

      <motion.div 
        className="factors-grid"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {factors.map((factor, index) => {
          const factorEmission = data.factorEmissions?.find(e => e.factor === factor.route.replace('-', '_'))?.total || 0;
          return (
            <motion.div
              key={factor.route}
              className="factor-card"
              onClick={() => onNavigate(factor.route)}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.1 * index }}
            >
              <div className="factor-card-content">
                <h3>{factor.name}</h3>
                <p>{factor.description}</p>
                <div className="factor-emissions">
                  {factorEmission.toFixed(2)} t CO₂e
                </div>
              </div>
              <div className="card-arrow">→</div>
            </motion.div>
          );
        })}

        {/* Request Factor Card */}
        <motion.div
          className="request-factor-card"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h3>Vermissen Sie einen Faktor?</h3>
          <p>Lassen Sie uns wissen, welchen zusätzlichen Scope 1 Faktor Sie benötigen.</p>
          <input
            type="text"
            className="request-factor-input"
            placeholder="Name des gewünschten Faktors"
            id="requestedFactor"
          />
          <button
            className="request-factor-button"
            onClick={() => {
              const factor = document.getElementById('requestedFactor')?.value || '';
              const mailtoLink = `mailto:support@liks.gmbh?subject=Neuer%20Scope%201%20Faktor%20Anfrage&body=Sehr%20geehrtes%20LIKS-Team%2C%0A%0AIch%20möchte%20gerne%20den%20folgenden%20Scope%201%20Faktor%20hinzufügen%3A%0A%0A${encodeURIComponent(factor)}%0A%0AMit%20freundlichen%20Grüßen`;
              window.location = mailtoLink;
            }}
          >
            Faktor vorschlagen
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Scope1;